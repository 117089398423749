import React, { useState } from 'react'
import Layout from '../Layout'
import AdminUsersManagement from './AdminUsersManagement'
import AdminReferrals from './AdminReferrals'
import AdminServicePlans from './AdminServicePlans'
import AdminAIFeedbacks from './AdminAIFeedbacks'
import AdminDevices from './AdminDevices'
import MobileAppLogs from './MobileAppLogs'
import AdminReports from './AdminReports'
import AdminUploadApplication from './AdminUploadApplication'
import AdminReferralsConfigMain from './AdminReferralsConfigMain'
import AdminQuestionnaireTemplates from './AdminQuestionnaireTemplates'
import AdminReferralsQueues from './AdminReferralsQueues'
import clsx from 'clsx'

const Admin = () => {
  const [tab, setTab] = useState(null)

  return (
    <Layout>
      <div className='section'>
        <div className='tabs'>
          <ul>
            <li
              className={clsx({
                'is-active': tab === 'users' || !tab
              })}
            >
              <a onClick={() => setTab('users')}>Users</a>
            </li>
            <li
              className={clsx({
                'is-active': tab === 'referrals'
              })}
            >
              <a onClick={() => setTab('referrals')}>Referrals</a>
            </li>
            <li
              className={clsx({
                'is-active': tab === 'servicePlans'
              })}
            >
              <a onClick={() => setTab('servicePlans')}>
                Service plans (Bluesnap)
              </a>
            </li>
            <li
              className={clsx({
                'is-active': tab === 'devices'
              })}
            >
              <a onClick={() => setTab('devices')}>Devices</a>
            </li>
            <li
              className={clsx({
                'is-active': tab === 'referralsConfig'
              })}
            >
              <a onClick={() => setTab('referralsConfig')}>Referrals configs</a>
            </li>
            <li
              className={clsx({
                'is-active': tab === 'questionnaireTemplates'
              })}
            >
              <a onClick={() => setTab('questionnaireTemplates')}>
                Questionnaire templates
              </a>
            </li>
            <li
              className={clsx({
                'is-active': tab === 'queues'
              })}
            >
              <a onClick={() => setTab('queues')}>Queues</a>
            </li>
            <li
              className={clsx({
                'is-active': tab === 'logs'
              })}
            >
              <a onClick={() => setTab('logs')}>Logs</a>
            </li>
            <li
              className={clsx({
                'is-active': tab === 'reports'
              })}
            >
              <a onClick={() => setTab('reports')}>Reports</a>
            </li>
            <li
              className={clsx({
                'is-active': tab === 'upload'
              })}
            >
              <a onClick={() => setTab('upload')}>Upload new application</a>
            </li>
            <li
              className={clsx({
                'is-active': tab === 'aiFeedbacks'
              })}
            >
              <a onClick={() => setTab('aiFeedbacks')}>AI feedbacks</a>
            </li>
          </ul>
        </div>

        <div className='container'>
          {(!tab || tab == 'users') && (
            <div>
              <AdminUsersManagement></AdminUsersManagement>
            </div>
          )}
          {tab == 'referrals' && (
            <div>
              <AdminReferrals></AdminReferrals>
            </div>
          )}
          {tab == 'servicePlans' && (
            <div>
              <AdminServicePlans></AdminServicePlans>
            </div>
          )}
          {tab == 'devices' && (
            <div>
              <AdminDevices></AdminDevices>
            </div>
          )}
          {tab == 'referralsConfig' && (
            <div>
              <AdminReferralsConfigMain></AdminReferralsConfigMain>
            </div>
          )}
          {tab == 'questionnaireTemplates' && (
            <div>
              <AdminQuestionnaireTemplates></AdminQuestionnaireTemplates>
            </div>
          )}
          {tab == 'queues' && (
            <div>
              <AdminReferralsQueues></AdminReferralsQueues>
            </div>
          )}
          {tab == 'logs' && (
            <div>
              <MobileAppLogs></MobileAppLogs>
            </div>
          )}
          {tab == 'reports' && (
            <div>
              <AdminReports></AdminReports>
            </div>
          )}
          {tab == 'upload' && (
            <div>
              <AdminUploadApplication></AdminUploadApplication>
            </div>
          )}
          {tab == 'aiFeedbacks' && (
            <div>
              <AdminAIFeedbacks></AdminAIFeedbacks>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Admin
