import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import Modal from '../Modal'
import clsx from 'clsx'
import { useLocalization } from '../LocalizationProvider'
import { REFERRAL_TYPES_CONVERTER } from '../../../utils/utils'
import {
  GET_REFERRALS_CONFIG,
  GET_REFERRALS_CONFIGS_WITH_DATA
} from '../queries'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpLong, faDownLong } from '@fortawesome/free-solid-svg-icons'

const UPDATE_REFERRAL_CONFIG_ORDER = gql`
  mutation UPDATE_REFERRAL_CONFIG_ORDER(
    $id: ID!
    $group: String!
    $studyProtocol: StudyProtocol!
    $referralType: ReferralType!
    $input: UserUpdateInReferralsConfigInput!
  ) {
    userUpdateInReferralsConfig(
      id: $id
      group: $group
      studyProtocol: $studyProtocol
      referralType: $referralType
      input: $input
    ) {
      id
    }
  }
`

const GraphqlSchemaEnums = JSON.parse(process.env.GraphqlSchemaEnums)
const protocolsInUse = ['Rest', 'Holter']

const UpdateReferralConfigOrder = ({
  group,
  caregiversByProtocol,
  configData,
  onExit,
  onComplete
}) => {
  const [inProgress, setProgress] = useState(false)
  const [caregiversByProtocolToEdit, setCaregiversByProtocolToEdit] =
    useState(caregiversByProtocol)
  const [configToEdit, setConfigToEdit] = useState(configData)
  const { locale } = useLocalization()

  const [userUpdateInReferralsConfig, { loading: isEditing }] = useMutation(
    UPDATE_REFERRAL_CONFIG_ORDER,
    {
      refetchQueries: [
        {
          query: GET_REFERRALS_CONFIG,
          variables: { referralsConfigId: group }
        },
        {
          query: GET_REFERRALS_CONFIGS_WITH_DATA
        }
      ],
      awaitRefetchQueries: true
    }
  )

  async function submit() {
    setProgress(true)

    await Promise.all(
      configToEdit.map((config) => {
        const newOrderRefIdArr = configToEdit
          .filter((c) => c.studyProtocol === config.studyProtocol)
          .filter((c) => c.caregiver.id === config.caregiver.id)
          .map((c) => c.id)

        return userUpdateInReferralsConfig({
          variables: {
            id: config.caregiver.id,
            group: group,
            studyProtocol: config.studyProtocol,
            referralType: config.referralType,
            input: {
              updatedAt: new Date().toISOString(),
              order:
                caregiversByProtocolToEdit[config.studyProtocol]
                  .map((caregiver) => caregiver.id)
                  .indexOf(config.caregiver.id) +
                parseFloat(
                  (1 / 9).toFixed(newOrderRefIdArr.indexOf(config.id) + 1)
                )
              // caregiverIndex + 0.11111... where the number of 1's depends on the order of the referral type
            }
          }
        })
      })
    )
    onComplete()
  }

  function moveReferral(referralsArray, referralIndex, direction) {
    const referralA = referralsArray[referralIndex].id
    const referralB = referralsArray[referralIndex + direction].id

    const referralIdArrray = configToEdit.map((r) => r.id)
    const referrralAIndex = referralIdArrray.indexOf(referralA)
    const referrralBIndex = referralIdArrray.indexOf(referralB)

    let _configToEdit = JSON.parse(JSON.stringify(configToEdit))
    const tmp = _configToEdit[referrralAIndex]
    _configToEdit[referrralAIndex] = _configToEdit[referrralBIndex]
    _configToEdit[referrralBIndex] = tmp
    setConfigToEdit(_configToEdit)
  }

  function moveCaregiver(protocol, caregiverIndex, direction) {
    let _caregiversByProtocolToEdit = JSON.parse(
      JSON.stringify(caregiversByProtocolToEdit)
    )
    const tmp = _caregiversByProtocolToEdit[protocol][caregiverIndex]
    _caregiversByProtocolToEdit[protocol][caregiverIndex] =
      _caregiversByProtocolToEdit[protocol][caregiverIndex + direction]
    _caregiversByProtocolToEdit[protocol][caregiverIndex + direction] = tmp
    setCaregiversByProtocolToEdit(_caregiversByProtocolToEdit)
  }

  return (
    <Modal onClose={onExit} onSubmit={submit} inProgress={inProgress}>
      <div>
        {GraphqlSchemaEnums.StudyProtocol.filter((protocol) =>
          protocolsInUse.includes(protocol)
        )
          .filter((protocol) => caregiversByProtocol[protocol].length > 0)
          .map((protocol) => {
            return (
              <div key={protocol}>
                {protocol}
                <ul className='ml-5'>
                  {caregiversByProtocolToEdit[protocol].map(
                    (caregiver, i, caregiversArr) => (
                      <li key={`${protocol}_${caregiver.id}`} className='mb-4'>
                        {`${caregiver.name}${
                          !caregiver.isQueue
                            ? ` (${caregiver.email})`
                            : ' (Queue)'
                        }`}
                        {i > 0 && (
                          <FontAwesomeIcon
                            className='ml-3 is-clickable'
                            icon={faUpLong}
                            onClick={() => {
                              moveCaregiver(protocol, i, -1)
                            }}
                          />
                        )}
                        {i < caregiversArr.length - 1 && (
                          <FontAwesomeIcon
                            className='ml-2 is-clickable'
                            icon={faDownLong}
                            onClick={() => {
                              moveCaregiver(protocol, i, 1)
                            }}
                          />
                        )}
                        <ul className='ml-5'>
                          {configToEdit
                            .filter(
                              (config) => config.studyProtocol === protocol
                            )
                            .filter(
                              (config) => config.caregiver.id === caregiver.id
                            )
                            .map((config, j, configsArr) => (
                              <li key={config.id + group} className='mt-1'>
                                {
                                  locale.studies.reports[
                                    REFERRAL_TYPES_CONVERTER[
                                      config.referralType
                                    ]
                                  ]
                                }
                                {j > 0 && (
                                  <FontAwesomeIcon
                                    className='ml-3 is-clickable'
                                    icon={faUpLong}
                                    onClick={() => {
                                      moveReferral(configsArr, j, -1)
                                    }}
                                  />
                                )}
                                {j < configsArr.length - 1 && (
                                  <FontAwesomeIcon
                                    className='ml-2 is-clickable'
                                    icon={faDownLong}
                                    onClick={() => {
                                      moveReferral(configsArr, j, 1)
                                    }}
                                  />
                                )}
                              </li>
                            ))}
                        </ul>
                      </li>
                    )
                  )}
                </ul>
              </div>
            )
          })}
      </div>
      <div>
        <div className='level-left'>
          <div className='level-item'></div>
        </div>
        <div className='level-right'>
          <div className='level-item'>
            <button
              className={clsx('button is-primary', {
                'is-loading': inProgress || isEditing
              })}
              onClick={async () => submit()}
            >
              ok
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default UpdateReferralConfigOrder
