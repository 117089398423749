import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_REFERRALS_CONFIG, GET_REFERRALS_CONFIGS } from '../queries'
import { useLocalization } from '../LocalizationProvider'
import Loader from '../Loader'
import { REFERRAL_TYPES_CONVERTER } from '../../../utils/utils'
import AddReferralConfig from './AddReferralConfig'
import UpdateReferralConfig from './UpdateReferralConfig'
import RemoveFromReferralConfig from './RemoveFromReferralConfig'
import UpdateReferralConfigOrder from './UpdateReferralConfigOrder'

const GraphqlSchemaEnums = JSON.parse(process.env.GraphqlSchemaEnums)

const AdminReferralsConfig = () => {
  const { locale } = useLocalization()
  const [group, setGroup] = useState(-1)
  const [isAddConfigMode, setAddConfigMode] = useState(false)
  const [configToUpdate, setConfigToUpdate] = useState(null)
  const [configToRemove, setConfigToRemove] = useState(null)
  const [configToReorder, setConfigToReorder] = useState(null)

  const {
    loading: configsLoading,
    error: configsError,
    data: configsData
  } = useQuery(GET_REFERRALS_CONFIGS, {})

  const {
    loading: configLoading,
    error: configError,
    data: configData
  } = useQuery(GET_REFERRALS_CONFIG, {
    variables: { referralsConfigId: group }
  })

  const protocolsInUse = ['Rest', 'Holter']

  // unique list of caregivers by protcol
  let caregiversByProtocol = configData
    ? protocolsInUse.reduce(
        (acc, curr) => (
          (acc[curr] = configData.referralsConfig
            .filter((cd) => cd.studyProtocol === curr)
            .map((c) => {
              return {
                id: c.caregiver.id,
                name: c.caregiver.name,
                email: c.caregiver.email,
                isQueue: c.isQueue,
                unavailableUntil: c.unavailableUntil
              }
            })
            .filter((v, i, a) => a.map((v) => v.id).indexOf(v.id) === i)),
          acc
        ),
        {}
      )
    : {}

  return (
    <div className='mt-5'>
      {(configError || configsError) && <div> error... </div>}
      {isAddConfigMode && (
        <AddReferralConfig
          referralConfigsData={configsData}
          onComplete={() => setAddConfigMode(false)}
          onExit={() => setAddConfigMode(false)}
        />
      )}
      {configToUpdate && (
        <UpdateReferralConfig
          group={group}
          configToEdit={configToUpdate}
          onComplete={() => setConfigToUpdate(null)}
          onExit={() => setConfigToUpdate(null)}
        />
      )}
      {configToRemove && (
        <RemoveFromReferralConfig
          group={group}
          configToRemove={configToRemove}
          onComplete={() => setConfigToRemove(null)}
          onExit={() => setConfigToRemove(null)}
        />
      )}
      {configToReorder && (
        <UpdateReferralConfigOrder
          group={group}
          caregiversByProtocol={caregiversByProtocol}
          configData={configData.referralsConfig}
          onComplete={() => setConfigToReorder(null)}
          onExit={() => setConfigToReorder(null)}
        />
      )}
      {configsLoading ? (
        <Loader />
      ) : (
        <div className='level'>
          <div className='level-left'>
            <div className='level-item'>
              <div className='select'>
                <select
                  value={group}
                  onChange={(e) => setGroup(e.target.value)}
                >
                  <option disabled='disabled' value='-1'>
                    Select group
                  </option>
                  {configsData.referralsConfigs.map(({ id: groupId }) => (
                    <option value={groupId} key={groupId}>
                      {groupId}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className='level-right'>
            <div className='level-item'>
              {configData && configData.referralsConfig.length > 0 && (
                <a
                  className='button is-primary mr-5'
                  href='#'
                  onClick={() => {
                    setConfigToReorder(true)
                  }}
                >
                  Edit order
                </a>
              )}
              <a
                className='button is-primary'
                href='#'
                onClick={() => {
                  setAddConfigMode(true)
                }}
              >
                Add
              </a>
            </div>
          </div>
        </div>
      )}
      {group !== -1 &&
        (configLoading ? (
          <Loader />
        ) : (
          GraphqlSchemaEnums.StudyProtocol.filter((protocol) =>
            protocolsInUse.includes(protocol)
          )
            .filter((protocol) => caregiversByProtocol[protocol].length > 0)
            .map((protocol) => {
              return (
                <div key={protocol} className='mb-6'>
                  <article className='panel'>
                    <p className='panel-heading'>{protocol}</p>

                    {caregiversByProtocol[protocol].map((caregiver) => (
                      <div key={`${protocol}_${caregiver.id}`} className='px-4'>
                        <div className='panel-block mt-4'>
                          <div className='title is-6'>
                            <span className='py-2'>{`${caregiver.name}${
                              !caregiver.isQueue
                                ? ` (${caregiver.email})`
                                : ' (Queue)'
                            }`}</span>
                            {caregiver.unavailableUntil && (
                              <span className='py-2 ml-2'>|</span>
                            )}
                            {caregiver.unavailableUntil && (
                              <span className='has-background-danger py-2  ml-2 px-2'>
                                unavailable until: {caregiver.unavailableUntil}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className='panel-block mb-2'>
                          <table className='table is-fullwidth'>
                            <tbody>
                              {configData.referralsConfig
                                .filter(
                                  (config) => config.studyProtocol === protocol
                                )
                                .filter(
                                  (config) =>
                                    config.caregiver.id === caregiver.id
                                )
                                .map((config) => (
                                  <tr key={config.id + group}>
                                    <td className='is-vcentered'>
                                      {
                                        locale.studies.reports[
                                          REFERRAL_TYPES_CONVERTER[
                                            config.referralType
                                          ]
                                        ]
                                      }
                                    </td>
                                    <td className='is-vcentered'>
                                      {config.price}
                                    </td>
                                    <td className='is-vcentered'>
                                      {config.currency}
                                    </td>
                                    <td className='is-vcentered'>
                                      <a
                                        className='button'
                                        href='#'
                                        onClick={() => {
                                          setConfigToUpdate(config)
                                        }}
                                      >
                                        Edit
                                      </a>
                                    </td>
                                    <td className='is-vcentered'>
                                      <a
                                        className='button'
                                        href='#'
                                        onClick={() => {
                                          setConfigToRemove(config)
                                        }}
                                      >
                                        Remove
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ))}
                  </article>
                </div>
              )
            })
        ))}
    </div>
  )
}

export default AdminReferralsConfig
