import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { gql, useMutation, useApolloClient } from '@apollo/client'
import Modal from '../Modal'
import { REFERRAL_TYPES_CONVERTER } from '../../../utils/utils'
import { useLocalization } from '../LocalizationProvider'
import clsx from 'clsx'
import {
  GET_ADMIN_DATA_FROM_USER_EMAIL,
  GET_REFERRALS_CONFIG,
  GET_REFERRALS_CONFIGS,
  GET_REFERRALS_QUEUES
} from '../queries'
import Loader from '../Loader'

const GraphqlSchemaEnums = JSON.parse(process.env.GraphqlSchemaEnums)

const ADD_TO_REFERRAL_CONFIG = gql`
  mutation ADD_TO_REFERRAL_CONFIG(
    $id: ID!
    $group: String!
    $studyProtocol: StudyProtocol!
    $referralType: ReferralType!
    $isQueue: Boolean!
    $input: UserAddToReferralsConfigInput!
  ) {
    userAddToReferralsConfig(
      id: $id
      group: $group
      studyProtocol: $studyProtocol
      referralType: $referralType
      isQueue: $isQueue
      input: $input
    ) {
      id
    }
  }
`

const AddReferralConfig = ({ referralConfigsData, onExit, onComplete }) => {
  const [inProgress, setProgress] = useState(false)
  const { locale } = useLocalization()
  const [result, setResult] = useState(null)
  const client = useApolloClient()

  const [selectedGroupIndex, setSelectedGroupIndex] = useState(-1)
  const [groupInput, setGroupInput] = useState('')
  const [caregiverEmailInput, setCaregiverEmailInput] = useState('')
  const [protocolInput, setProtocolInput] = useState(-1)
  const [typeInput, setTypeInput] = useState(-1)
  const [priceInput, setPriceInput] = useState(0)
  const [currencyInput, setCurrencyInput] = useState(-1)
  const [isQueueInput, setIsQueueInput] = useState(false)
  const [queueInput, setQueueInput] = useState(-1)

  const protocolsInUse = ['Rest', 'Holter']

  const { loading: queuesLoading, data: queuesData } = useQuery(
    GET_REFERRALS_QUEUES,
    {}
  )

  const [userAddToReferralsConfig, { loading: isAdding }] = useMutation(
    ADD_TO_REFERRAL_CONFIG,
    {
      refetchQueries: [
        {
          query: GET_REFERRALS_CONFIG,
          variables: { referralsConfigId: groupInput }
        },
        {
          query: GET_REFERRALS_CONFIGS
        }
      ],
      awaitRefetchQueries: true
    }
  )

  const {
    loading: configLoading,
    error: configError,
    data: configData
  } = useQuery(GET_REFERRALS_CONFIG, {
    variables: { referralsConfigId: groupInput }
  })

  async function submit() {
    setProgress(true)
    setResult(null)
    try {
      let id = queueInput
      if (!isQueueInput) {
        const {
          data: {
            admin: { userByEmail }
          }
        } = await client.query({
          query: GET_ADMIN_DATA_FROM_USER_EMAIL,
          variables: {
            email: caregiverEmailInput
          }
        })
        id = userByEmail ? userByEmail.id : false
      }
      if (id) {
        const config = configData.referralsConfig
        const protocolConfig = config.filter(
          (c) => c.studyProtocol === protocolInput
        )
        const caregiverInConfig = protocolConfig.filter(
          (c) => c.caregiver.id === id
        )

        console.log(protocolConfig)
        const newOrder =
          protocolConfig.length === 0 // New config
            ? 0.1
            : caregiverInConfig.length === 0 // New caregiver in the config
            ? parseInt(protocolConfig[protocolConfig.length - 1].order) + 1.1
            : parseInt(caregiverInConfig[0].order) +
              parseFloat((1 / 9).toFixed(caregiverInConfig.length + 1)) // caregiverIndex + 0.11111... where the number of 1's depends on the order of the referral type

        await userAddToReferralsConfig({
          variables: {
            id: id,
            group: groupInput,
            studyProtocol: protocolInput,
            referralType: typeInput,
            isQueue: isQueueInput,
            input: {
              currency: currencyInput,
              price: priceInput,
              createdAt: new Date().toISOString(),
              order: newOrder
            }
          }
        })
        onComplete()
      } else {
        setResult("Can't find the user")
      }
    } catch (e) {
      setResult('Make sure the email is valid.')
    }
    setProgress(false)
  }

  return (
    <Modal onClose={onExit} onSubmit={submit} inProgress={inProgress}>
      {configError && <div> error... </div>}
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Group:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='select'>
              <select
                value={selectedGroupIndex}
                onChange={(e) => {
                  setSelectedGroupIndex(e.target.value)
                  setGroupInput(
                    e.target.value != '-1'
                      ? referralConfigsData.referralsConfigs[e.target.value].id
                      : ''
                  )
                }}
              >
                <option value='-1'>New group</option>
                {referralConfigsData.referralsConfigs.map(({ id }, index) => (
                  <option value={index} key={id}>
                    {id}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Group name:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <input
              className='input'
              type='text'
              placeholder='group'
              value={groupInput}
              disabled={selectedGroupIndex != '-1'}
              onChange={(e) => setGroupInput(e.target.value)}
            ></input>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Is queue:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='control'>
              <label className='checkbox'>
                <input
                  type='checkbox'
                  checked={isQueueInput}
                  onChange={(e) => setIsQueueInput(e.target.checked)}
                  className='mr-2'
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      {isQueueInput ? (
        queuesLoading ? (
          <Loader />
        ) : (
          <div className='field is-horizontal'>
            <div className='field-label'>
              <label className='label has-text-left'>Queue:</label>
            </div>
            <div className='field-body'>
              <div className='field'>
                <div className='control'>
                  <div className='select'>
                    <select
                      value={queueInput}
                      onChange={(e) => setQueueInput(e.target.value)}
                    >
                      <option disabled='disabled' value='-1'>
                        Select queue
                      </option>
                      {queuesData.referralsQueues.map(
                        ({ id: queueId, name: queueName }) => (
                          <option value={queueId} key={queueId}>
                            {queueName}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className='field is-horizontal'>
          <div className='field-label'>
            <label className='label has-text-left'>Caregiver email:</label>
          </div>
          <div className='field-body'>
            <div className='field'>
              <div className='control'>
                <input
                  className='input'
                  type='text'
                  placeholder='Caregiver email'
                  value={caregiverEmailInput}
                  onChange={(e) => setCaregiverEmailInput(e.target.value)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Protocol:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='control'>
              <div className='select'>
                <select
                  value={protocolInput}
                  onChange={(e) => setProtocolInput(e.target.value)}
                >
                  <option disabled='disabled' value='-1'>
                    Select protocol
                  </option>
                  {GraphqlSchemaEnums.StudyProtocol.filter((protocol) =>
                    protocolsInUse.includes(protocol)
                  ).map((protocol) => (
                    <option value={protocol} key={protocol}>
                      {protocol}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Type:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='control'>
              <div className='select'>
                <select
                  value={typeInput}
                  onChange={(e) => setTypeInput(e.target.value)}
                >
                  <option disabled='disabled' value='-1'>
                    Select type
                  </option>
                  {GraphqlSchemaEnums.ReferralType.map((type) => (
                    <option value={type} key={type}>
                      {locale.studies.reports[REFERRAL_TYPES_CONVERTER[type]]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Price:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='control'>
              <input
                className='input'
                type='number'
                placeholder='Caregiver email'
                value={priceInput}
                min='0'
                onChange={(e) => setPriceInput(e.target.value)}
              ></input>
            </div>
          </div>
        </div>
      </div>
      <div className='field is-horizontal'>
        <div className='field-label'>
          <label className='label has-text-left'>Currency:</label>
        </div>
        <div className='field-body'>
          <div className='field'>
            <div className='control'>
              <div className='select'>
                <select
                  value={currencyInput}
                  onChange={(e) => setCurrencyInput(e.target.value)}
                >
                  <option disabled='disabled' value='-1'>
                    Select currency
                  </option>
                  {GraphqlSchemaEnums.CurrencyType.map((currency) => (
                    <option value={currency} key={currency}>
                      {currency}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <label className='has-text-danger-dark'>{result}</label>
          </div>
        </div>
        <div className='level-right'>
          <div className='level-item'>
            <button
              className={clsx('button is-primary', {
                'is-loading': inProgress || isAdding || configLoading
              })}
              onClick={async () => submit()}
            >
              ok
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AddReferralConfig
