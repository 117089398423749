import React, { useState, useEffect } from 'react'
import {
  gql,
  useMutation,
  useQuery,
  useLazyQuery,
  useApolloClient
} from '@apollo/client'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown,
  faMagnifyingGlass
} from '@fortawesome/free-solid-svg-icons'
import Papa from 'papaparse'
import { GET_REFERRALS_CONFIGS } from '../queries'
import useDebounce from '../../hooks/useDebounce'
import Loader from '../Loader'

const FIRST = 25

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const GENERATE_ADMIN_REPORT = gql`
  mutation GENERATE_ADMIN_REPORT(
    $type: AdminReportType!
    $group: String!
    $fromDate: AWSDate!
    $toDate: AWSDate
    $excludes: [String]!
  ) {
    startAdminReportGeneration(
      type: $type
      group: $group
      fromDate: $fromDate
      toDate: $toDate
      excludes: $excludes
    ) {
      id
    }
  }
`

const GET_ADMIN_REPORT_STATUS = gql`
  query GET_ADMIN_REPORT_STATUS($id: ID!, $type: AdminReportType!) {
    admin {
      id
      reportStatus(id: $id, type: $type)
    }
  }
`

const GET_ADMIN_REPORT_RESULTS = gql`
  query GET_ADMIN_REPORT_RESULTS($id: ID!) {
    admin {
      id
      reportResults(id: $id) {
        url
      }
    }
  }
`

const AdminReports = () => {
  const [type, setType] = useState('users')
  const [group, setGroup] = useState('-1')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [excludes, setExcludes] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [isValidToDate, setIsValidToDate] = useState(true)
  const [isShowRequiredFields, setIsShowRequiredFields] = useState(false)
  const [results, setResults] = useState([])
  const [nodes, setNodes] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [filterBy, setFilterBy] = useState('')
  const debouncedFilterBy = useDebounce(filterBy.toLowerCase(), 500)
  const [generateAdminReport] = useMutation(GENERATE_ADMIN_REPORT)
  const [getAdminReportStatus] = useLazyQuery(GET_ADMIN_REPORT_STATUS, {
    fetchPolicy: 'network-only'
  })
  const {
    loading: configsLoading,
    error: configsError,
    data: configsData
  } = useQuery(GET_REFERRALS_CONFIGS, {})

  const client = useApolloClient()

  useEffect(() => {
    setNodes(
      results.filter(
        (node) =>
          JSON.stringify(Object.values(node))
            .toLowerCase()
            .indexOf(debouncedFilterBy) > -1
      )
    )
  }, [results, debouncedFilterBy])

  const now = new Date()
  const oneMonthAgo = new Date()
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)

  return (
    <div className='mt-5'>
      {configsLoading ? (
        <Loader />
      ) : (
        <div>
          <div className='field is-horizontal mt-5'>
            <div className='field-body'>
              <div className='field is-narrow'>
                <div className='control'>
                  <div className='select'>
                    <select
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value='users'>users</option>
                      <option value='reports'>reports</option>
                      <option value='reportsByCardiologists'>
                        reports by cardiologists
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className='field is-narrow'>
                <div className='control'>
                  <div className='select'>
                    <select
                      value={group}
                      onChange={(e) => setGroup(e.target.value)}
                      disabled={type === 'reportsByCardiologists'}
                    >
                      <option disabled='disabled' value='-1'>
                        Select group
                      </option>
                      {configsData.referralsConfigs.map(({ id: groupId }) => (
                        <option value={groupId} key={groupId}>
                          {groupId}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {isShowRequiredFields && (
                  <p className='help is-danger'>This field is required</p>
                )}
              </div>
              <div className='field is-narrow'>
                <div className='control'>
                  <input
                    type='month'
                    className={clsx('input', {
                      'is-danger': isShowRequiredFields
                    })}
                    placeholder='from date'
                    max={oneMonthAgo
                      .toISOString()
                      .split('-')
                      .slice(0, 2)
                      .join('-')}
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </div>
                {isShowRequiredFields && (
                  <p className='help is-danger'>This field is required</p>
                )}
              </div>
              <div className='field is-narrow'>
                <div className='control'>
                  <input
                    type='month'
                    className={clsx('input', { 'is-danger': !isValidToDate })}
                    placeholder='to date'
                    max={now.toISOString().split('-').slice(0, 2).join('-')}
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </div>
                {!isValidToDate && (
                  <p className='help is-danger'>
                    Should be greater than from date
                  </p>
                )}
              </div>
              <div className='field'>
                <div className='control'>
                  <input
                    type='text'
                    className='input'
                    placeholder='excludes'
                    value={excludes}
                    onChange={(e) => setExcludes(e.target.value)}
                    disabled={type === 'reportsByCardiologists'}
                  />
                </div>
              </div>
              <div className='field is-narrow'>
                <div className='control'>
                  <button
                    className={clsx('button is-primary', {
                      'is-loading': isLoading
                    })}
                    disabled={isLoading}
                    onClick={async () => {
                      setIsShowRequiredFields(false)
                      setIsValidToDate(true)
                      if (
                        (type !== 'reportsByCardiologists' && group === '-1') ||
                        !fromDate
                      ) {
                        setIsShowRequiredFields(true)
                        return
                      }
                      if (toDate && toDate <= fromDate) {
                        setIsValidToDate(false)
                        return
                      }
                      setResults([])
                      setIsLoading(true)
                      const {
                        data: {
                          startAdminReportGeneration: { id }
                        }
                      } = await generateAdminReport({
                        variables: {
                          type,
                          group,
                          fromDate: fromDate + '-01',
                          toDate: toDate ? toDate + '-01' : undefined,
                          excludes: excludes.split(',').filter((value) => value)
                        }
                      })

                      let status = 'RUNNING'
                      while (status === 'RUNNING') {
                        await sleep(1000)
                        let {
                          data: {
                            admin: { reportStatus }
                          }
                        } = await getAdminReportStatus({
                          variables: {
                            id,
                            type
                          }
                        })
                        status = reportStatus
                      }
                      if (status === 'SUCCEEDED') {
                        const {
                          data: {
                            admin: {
                              reportResults: { url }
                            }
                          }
                        } = await client.query({
                          query: GET_ADMIN_REPORT_RESULTS,
                          fetchPolicy: 'network-only',
                          variables: {
                            id
                          }
                        })
                        const response = await window.fetch(url)
                        const csv = await response.text()
                        const data = Papa.parse(csv, {
                          header: true,
                          skipEmptyLines: 'greedy'
                        })
                        setResults(data.data)
                      } else if (status === 'FAILED') {
                        setIsError(true)
                      }
                      setIsLoading(false)
                    }}
                  >
                    Generate
                  </button>
                </div>
              </div>
              <div className='control'>
                <div className='field is-narrow'>
                  <div
                    className={clsx('dropdown is-right', {
                      'is-active': isActive
                    })}
                  >
                    <div className='dropdown-trigger'>
                      <button
                        className='button is-primary'
                        disabled={nodes.length === 0}
                        onClick={() => setIsActive(!isActive)}
                      >
                        <span>Download as</span>
                        <span className='icon is-small'>
                          <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                      </button>
                    </div>
                    <div className='dropdown-menu'>
                      <div className='dropdown-content'>
                        <a
                          className='dropdown-item'
                          href='#'
                          download={`${
                            type !== 'reportsByCardiologists' ? `${group}.` : ''
                          }${type}.${fromDate}-${toDate || 'now'}${
                            debouncedFilterBy
                              ? `.filterby-${debouncedFilterBy}`
                              : ''
                          }.json`}
                          onClick={(e) => {
                            const url = window.URL.createObjectURL(
                              new Blob([JSON.stringify(nodes)], {
                                type: 'application/json'
                              })
                            )
                            e.target.href = url
                            setTimeout(
                              () => window.URL.revokeObjectURL(url),
                              30000
                            )
                          }}
                          target='_blank'
                          rel='noreferrer'
                        >
                          json
                        </a>
                        <a
                          className='dropdown-item'
                          href='#'
                          download={`${
                            type !== 'reportsByCardiologists' ? `${group}.` : ''
                          }${type}.${fromDate}-${toDate || 'now'}${
                            debouncedFilterBy
                              ? `.filterby-${debouncedFilterBy}`
                              : ''
                          }.csv`}
                          onClick={(e) => {
                            const url = window.URL.createObjectURL(
                              new Blob(
                                [Papa.unparse(nodes, { quotes: true })],
                                {
                                  type: 'text/csv'
                                }
                              )
                            )
                            e.target.href = url
                            setTimeout(
                              () => window.URL.revokeObjectURL(url),
                              30000
                            )
                          }}
                          target='_blank'
                          rel='noreferrer'
                        >
                          csv
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {results.length > 0 && (
            <div className='field mt-5'>
              <p className='control has-icons-left has-icons-right'>
                <input
                  className='input'
                  type='text'
                  value={filterBy}
                  onChange={(e) => setFilterBy(e.target.value)}
                />
                <span className='icon is-small is-left'>
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </span>
              </p>
            </div>
          )}
          {nodes.length > 0 ? (
            <>
              <div className='has-text-centered mt-5 mb-5'>
                <span className='tag is-success is-large'>
                  {nodes.length} Results
                </span>
              </div>
              <div className='table-container'>
                <table className='table is-fullwidth is-hoverable is-striped'>
                  <thead>
                    <tr>
                      {Object.keys(nodes[0]).map((title) => (
                        <th key={title}>{title}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {nodes
                      .slice(currentPage * FIRST, (currentPage + 1) * FIRST)
                      .map((item) => (
                        <tr
                          key={JSON.stringify(item)}
                          style={{ textWrap: 'nowrap' }}
                        >
                          {Object.keys(item).map((key) => (
                            <td key={key}>{item[key]}</td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <nav
                className='pagination'
                role='navigation'
                aria-label='pagination'
              >
                <a
                  className='pagination-previous'
                  disabled={currentPage === 0}
                  onClick={() => {
                    if (currentPage === 0) return
                    setCurrentPage(currentPage - 1)
                  }}
                >
                  Previous
                </a>
                <a
                  className='pagination-next'
                  disabled={(currentPage + 1) * FIRST >= nodes.length}
                  onClick={() => {
                    if ((currentPage + 1) * FIRST >= nodes.length) return
                    setCurrentPage(currentPage + 1)
                  }}
                >
                  Next
                </a>
              </nav>
            </>
          ) : isLoading ? (
            <div className='has-text-centered mt-5'>
              <Loader />
            </div>
          ) : nodes.length === 0 ? (
            <div className='has-text-centered mt-5'>
              <span className='tag is-warning is-large'>No Results</span>
            </div>
          ) : isError || configsError ? (
            <div className='has-text-centered mt-5'>
              <span className='tag is-danger is-large'>Error</span>
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default AdminReports
