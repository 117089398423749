import React, { useState } from 'react'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import AdminPendingServicePlans from './AdminPendingServicePlans'
import UpdateServicePlanPrice from './UpdateServicePlanPrice'
import UpdateServicePlanBillingDate from './UpdateServicePlanBillingDate'
import UpdateServicePlanAutoRenew from './UpdateServicePlanAutoRenew'
import UpdateServicePlanActive from './UpdateServicePlanActive'
import AttachToServicePlan from './AttachToServicePlan'
import DetachFromServicePlan from './DetachFromServicePlan'

const AdminUserServicePlan = ({ clinic }) => {
  const [isPriceEditMode, setPriceEditMode] = useState(false)
  const [isBillingDateEditMode, setBillingDateEditMode] = useState(false)
  const [isAutoRenewEditMode, setAutoRenewEditMode] = useState(false)
  const [isSetChangeActiveMode, setChangeActiveMode] = useState(false)
  const [isAttachToServicePlanMode, setAttachToServicePlanMode] =
    useState(false)
  const [isDetachFromServicePlanMode, setDetachFromServicePlanMode] =
    useState(false)

  const servicePlans = clinic.servicePlans.filter(
    (servicePlan) => servicePlan.provider !== 'Beecardia'
  )
  const servicePlanBeecardia = clinic.servicePlans.find(
    (servicePlan) => servicePlan.provider === 'Beecardia'
  )

  if (servicePlans.length === 0 && servicePlanBeecardia) {
    servicePlans.push(servicePlanBeecardia)
  }

  if (servicePlans.length === 0) {
    return (
      <div>
        <p className='title is-6'>No service plan is attached.</p>
      </div>
    )
  }

  return (
    <div>
      {isPriceEditMode && (
        <UpdateServicePlanPrice
          servicePlan={isPriceEditMode}
          onComplete={() => {
            setPriceEditMode(false)
          }}
          onExit={() => setPriceEditMode(false)}
        ></UpdateServicePlanPrice>
      )}
      {isBillingDateEditMode && (
        <UpdateServicePlanBillingDate
          servicePlan={isBillingDateEditMode}
          onComplete={() => {
            setBillingDateEditMode(false)
          }}
          onExit={() => setBillingDateEditMode(false)}
        ></UpdateServicePlanBillingDate>
      )}
      {isAutoRenewEditMode && (
        <UpdateServicePlanAutoRenew
          servicePlan={isAutoRenewEditMode}
          onComplete={() => {
            setAutoRenewEditMode(false)
          }}
          onExit={() => setAutoRenewEditMode(false)}
        ></UpdateServicePlanAutoRenew>
      )}
      {isSetChangeActiveMode && (
        <UpdateServicePlanActive
          servicePlan={isSetChangeActiveMode}
          onComplete={() => {
            setChangeActiveMode(false)
          }}
          onExit={() => setChangeActiveMode(false)}
        ></UpdateServicePlanActive>
      )}
      {isAttachToServicePlanMode && (
        <AttachToServicePlan
          servicePlan={isAttachToServicePlanMode}
          onComplete={() => {
            setAttachToServicePlanMode(false)
          }}
          onExit={() => setAttachToServicePlanMode(false)}
        ></AttachToServicePlan>
      )}
      {isDetachFromServicePlanMode && (
        <DetachFromServicePlan
          servicePlan={isDetachFromServicePlanMode}
          clinic={clinic}
          onComplete={() => {
            setDetachFromServicePlanMode(false)
          }}
          onExit={() => setDetachFromServicePlanMode(false)}
        ></DetachFromServicePlan>
      )}
      {servicePlans.map((servicePlan) => (
        <div key={servicePlan.id}>
          <div>
            <div className='has-text-right mb-5'>
              {servicePlan.provider !== 'Beecardia' ? (
                <>
                  <button
                    className='button is-primary is-outlined'
                    onClick={() => setPriceEditMode(servicePlan)}
                  >
                    Change price
                  </button>
                  <button
                    className='button is-primary is-outlined ml-3'
                    onClick={() => setBillingDateEditMode(servicePlan)}
                  >
                    Change billing date
                  </button>
                  <button
                    className='button is-primary is-outlined ml-3'
                    onClick={() => setAutoRenewEditMode(servicePlan)}
                    disabled={!servicePlan.autoRenew}
                  >
                    Cancel auto renew
                  </button>
                  <button
                    className='button is-primary is-outlined ml-3'
                    onClick={() => setAttachToServicePlanMode(servicePlan)}
                  >
                    Attach another user to the service plan
                  </button>
                  <button
                    className='button is-primary is-outlined ml-3'
                    onClick={() => setDetachFromServicePlanMode(servicePlan)}
                  >
                    Detach user from this service plan
                  </button>
                </>
              ) : (
                <button
                  className='button is-primary is-outlined'
                  onClick={() => setChangeActiveMode(servicePlan)}
                >
                  {servicePlan.active ? 'deactivate' : 'activate'}
                </button>
              )}
            </div>
            <div className='columns'>
              <div className='column is-2 has-text-weight-bold'>Provider:</div>
              <div className='column'>{servicePlan.provider}</div>
            </div>
            {servicePlan.product && (
              <div className='columns'>
                <div className='column is-2 has-text-weight-bold'>Product:</div>
                <div className='column'>{servicePlan.product.name}</div>
              </div>
            )}
            {servicePlan.contract && (
              <>
                <div className='columns'>
                  <div className='column is-2 has-text-weight-bold'>
                    Contract:
                  </div>
                  <div className='column'>{servicePlan.contract.name}</div>
                </div>
                <div className='columns'>
                  <div className='column is-2 has-text-weight-bold'>
                    Subscription id:
                  </div>
                  <div className='column'>{servicePlan.id}</div>
                </div>
                <div className='columns'>
                  <div className='column is-2 has-text-weight-bold'>Price:</div>
                  <div className='column'>
                    {servicePlan.contract.price} {servicePlan.contract.currency}
                  </div>
                </div>
                <div className='columns'>
                  <div className='column is-2 has-text-weight-bold'>
                    Credit Card:
                  </div>
                  <div className='column'>
                    {`${servicePlan.paymentToken.creditCard.type} ${servicePlan.paymentToken.creditCard.number} ${servicePlan.paymentToken.creditCard.month}/${servicePlan.paymentToken.creditCard.year}`}
                  </div>
                </div>
                <div className='columns'>
                  <div className='column is-2 has-text-weight-bold'>
                    Next billing date:
                  </div>
                  <div className='column'>
                    {servicePlan.contract.nextBillingDate}
                  </div>
                </div>
              </>
            )}
            {servicePlan.payer && (
              <div className='columns'>
                <div className='column is-2 has-text-weight-bold'>Payer:</div>
                <div className='column'>{servicePlan.payer}</div>
              </div>
            )}
            <div className='columns'>
              <div className='column is-2 has-text-weight-bold'>Active: </div>
              <div className='column'>
                <FontAwesomeIcon
                  icon={servicePlan.active ? faCheck : faTimes}
                />
              </div>
            </div>
            <div className='columns'>
              <div className='column is-2 has-text-weight-bold'>
                Auto renew:{' '}
              </div>
              <div className='column'>
                <FontAwesomeIcon
                  icon={servicePlan.autoRenew ? faCheck : faTimes}
                />
              </div>
            </div>
            <div className='columns'>
              <div className='column is-2 has-text-weight-bold'>Created: </div>
              <div className='column'>
                {dayjs(servicePlan.createdAt).format('YYYY-MM-DD')}
              </div>
            </div>
            {servicePlan.clinics && servicePlan.clinics.length > 1 && (
              <div className='columns'>
                <div className='column is-2 has-text-weight-bold'>
                  Users in the same plan:
                </div>
                <div className='column'>
                  {servicePlan.clinics
                    .map(
                      (_clinic) =>
                        `${_clinic.owner.settings.displayName} (${_clinic.owner.email})`
                    )
                    .join(', ')}
                </div>
              </div>
            )}
          </div>
          <hr />
        </div>
      ))}
      <div className='mt-6'>
        <AdminPendingServicePlans clinic={clinic}></AdminPendingServicePlans>
      </div>
    </div>
  )
}

export default AdminUserServicePlan
