import React, { useState } from 'react'
import { GET_SERVICE_PLANS } from '../queries'
import { useQuery } from '@apollo/client'
// import useMemoryPagination, {
//   prev,
//   next
// } from '../../hooks/useMemoryPagination'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faTimes,
  faMagnifyingGlass
} from '@fortawesome/free-solid-svg-icons'
import UpdateServicePlanPrice from './UpdateServicePlanPrice'
import UpdateServicePlanBillingDate from './UpdateServicePlanBillingDate'
import UpdateServicePlanAutoRenew from './UpdateServicePlanAutoRenew'
import AttachToServicePlan from './AttachToServicePlan'
import clsx from 'clsx'

const FIRST = 25

const AdminAttachedServicePlans = () => {
  const [editPriceServicePlan, setEditPriceServicePlan] = useState(null)
  const [editBillingDateServicePlan, setEditBillingDateServicePlan] =
    useState(null)
  const [editAutoRenewServicePlan, setEditAutoRenewServicePlan] = useState(null)
  const [isAttachToServicePlanMode, setAttachToServicePlanMode] =
    useState(false)
  // const [paginationState, dispatch] = useMemoryPagination()
  const [highlightedServicePlan, setHighlightedServicePlan] = useState(null)

  // const { loading, error, data } = useQuery(GET_SERVICE_PLANS, {
  //   variables: { first: FIRST, after: paginationState }
  // })
  const { loading, error, data } = useQuery(GET_SERVICE_PLANS)
  const [currentPage, setCurrentPage] = useState(0)
  const [filterBy, setFilterBy] = useState('')

  const filterByLowerCase = filterBy.toLowerCase()

  const nodes = data
    ? data.admin.servicePlans.edges.filter(
        ({ node }) =>
          JSON.stringify(Object.values(node))
            .toLowerCase()
            .indexOf(filterByLowerCase) > -1
      )
    : []

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <div> error... </div>}

      {editPriceServicePlan && (
        <UpdateServicePlanPrice
          servicePlan={editPriceServicePlan}
          onComplete={() => {
            setEditPriceServicePlan(null)
          }}
          onExit={() => setEditPriceServicePlan(null)}
        ></UpdateServicePlanPrice>
      )}
      {editBillingDateServicePlan && (
        <UpdateServicePlanBillingDate
          servicePlan={editBillingDateServicePlan}
          onComplete={() => {
            setEditBillingDateServicePlan(null)
          }}
          onExit={() => setEditBillingDateServicePlan(null)}
        ></UpdateServicePlanBillingDate>
      )}
      {editAutoRenewServicePlan && (
        <UpdateServicePlanAutoRenew
          servicePlan={editAutoRenewServicePlan}
          onComplete={() => {
            setEditAutoRenewServicePlan(null)
          }}
          onExit={() => setEditAutoRenewServicePlan(null)}
        ></UpdateServicePlanAutoRenew>
      )}
      {isAttachToServicePlanMode && (
        <AttachToServicePlan
          servicePlan={isAttachToServicePlanMode}
          onComplete={() => {
            setAttachToServicePlanMode(false)
          }}
          onExit={() => setAttachToServicePlanMode(false)}
        ></AttachToServicePlan>
      )}
      {data && (
        <>
          <div className='field'>
            <p className='control has-icons-left has-icons-right'>
              <input
                className='input'
                type='text'
                value={filterBy}
                onChange={(e) => setFilterBy(e.target.value)}
              />
              <span className='icon is-small is-left'>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </span>
            </p>
          </div>
          <div className='table-container'>
            <table className='table is-fullwidth is-hoverable'>
              <thead>
                <tr>
                  <th>Created at</th>
                  <th>Attached users</th>
                  <th>Active</th>
                  <th>Auto renew</th>
                  <th>Product</th>
                  <th>Contract</th>
                  <th>Subscription id</th>
                  <th>Price</th>
                  <th>Currency</th>
                  <th>Credit card</th>
                  <th>Next billing date</th>
                  <th>paid with email</th>
                  <th>Change price</th>
                  <th>Change billing date</th>
                  <th>Cancel auto renew</th>
                  <th>Attach user</th>
                </tr>
              </thead>
              <tbody>
                {nodes
                  .slice(currentPage * FIRST, (currentPage + 1) * FIRST)
                  .map(({ node: servicePlan }) => (
                    <tr
                      key={servicePlan.id}
                      style={{ textWrap: 'nowrap' }}
                      className={clsx('c-hand', {
                        'is-selected': highlightedServicePlan === servicePlan.id
                      })}
                      onClick={() => {
                        setHighlightedServicePlan(
                          highlightedServicePlan === servicePlan.id
                            ? null
                            : servicePlan.id
                        )
                      }}
                    >
                      <td className='is-vcentered'>
                        {dayjs(servicePlan.createdAt).format('YYYY-MM-DD')}
                      </td>
                      <td className='is-vcentered'>
                        {servicePlan.clinics
                          .map(
                            (clinic) =>
                              `${clinic.owner.settings.displayName} (${clinic.owner.email})`
                          )
                          .join(', ')}
                      </td>
                      <td className='is-vcentered'>
                        <FontAwesomeIcon
                          icon={servicePlan.active ? faCheck : faTimes}
                        />
                      </td>
                      <td className='is-vcentered'>
                        <FontAwesomeIcon
                          icon={servicePlan.autoRenew ? faCheck : faTimes}
                        />
                      </td>
                      <td className='is-vcentered'>
                        {servicePlan.product.name}
                      </td>
                      <td className='is-vcentered'>
                        {servicePlan.contract.name}
                      </td>
                      <td className='is-vcentered'>{servicePlan.id}</td>
                      <td className='is-vcentered'>
                        {servicePlan.contract.price}
                      </td>
                      <td className='is-vcentered'>
                        {servicePlan.contract.currency}
                      </td>
                      <td className='is-vcentered'>
                        {`${servicePlan.paymentToken.creditCard.type} ${servicePlan.paymentToken.creditCard.number} ${servicePlan.paymentToken.creditCard.month}/${servicePlan.paymentToken.creditCard.year}`}
                      </td>
                      <td className='is-vcentered'>
                        {servicePlan.contract.nextBillingDate}
                      </td>
                      <td className='is-vcentered'>{servicePlan.payer}</td>
                      <td className='is-vcentered'>
                        <button
                          className='button'
                          onClick={(e) => {
                            setEditPriceServicePlan(servicePlan)
                            e.stopPropagation()
                          }}
                        >
                          Change price
                        </button>
                      </td>
                      <td className='is-vcentered'>
                        <button
                          className='button'
                          onClick={(e) => {
                            setEditBillingDateServicePlan(servicePlan)
                            e.stopPropagation()
                          }}
                        >
                          Change billing date
                        </button>
                      </td>
                      <td className='is-vcentered'>
                        <button
                          className='button'
                          onClick={(e) => {
                            setEditAutoRenewServicePlan(servicePlan)
                            e.stopPropagation()
                          }}
                          disabled={!servicePlan.autoRenew}
                        >
                          Cancel
                        </button>
                      </td>
                      <td className='is-vcentered'>
                        <button
                          className='button'
                          onClick={(e) => {
                            setAttachToServicePlanMode(servicePlan)
                            e.stopPropagation()
                          }}
                        >
                          Attach user
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <nav className='pagination' role='navigation' aria-label='pagination'>
            <a
              className='pagination-previous'
              // disabled={!data.admin.servicePlans.pageInfo.hasPreviousPage}
              // onClick={() => {
              //   if (data.admin.servicePlans.pageInfo.hasPreviousPage) {
              //     dispatch(prev())
              //   }
              // }}
              disabled={currentPage === 0}
              onClick={() => {
                if (currentPage === 0) return
                setCurrentPage(currentPage - 1)
              }}
            >
              Previous
            </a>
            <a
              className='pagination-next'
              disabled={(currentPage + 1) * FIRST >= nodes.length}
              onClick={() => {
                if ((currentPage + 1) * FIRST >= nodes.length) return
                setCurrentPage(currentPage + 1)
              }}
            >
              Next
            </a>
          </nav>
        </>
      )}
    </div>
  )
}

export default AdminAttachedServicePlans
