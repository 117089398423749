import React, { useState } from 'react'
import { GET_AI_FEEDBACKS } from '../queries'
import { useQuery } from '@apollo/client'
import clsx from 'clsx'
import Papa from 'papaparse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleDown,
  faCircle,
  faCheck,
  faXmark
} from '@fortawesome/free-solid-svg-icons'
import AIResultModal from '../AIResultModal'
// import useMemoryPagination, {
//   prev,
//   next
// } from '../../hooks/useMemoryPagination'
import dayjs from 'dayjs'

const FIRST = 25

function formatAIFeedbacks(edges) {
  return edges.map(({ node }) => ({
    'Created by': node.caregiver.name,
    'Created at': dayjs(node.createdAt).format('DD-MM-YYYY HH:mm:ss'),
    Result: !node.aiResult.value,
    'Is correct?': node.isCorrect,
    Notes: node.notes,
    Tags: node.tags.join(', '),
    Study: `${window.location.origin}/study/${node.studyId}`
  }))
}

const AdminAIFeedbacks = () => {
  const [isShowAIResultModal, setIsShowAIResultModal] = useState(false)

  // const [paginationState, dispatch] = useMemoryPagination()

  const [expandedNotesId, setExpandedNotesId] = useState(null)

  const [currentPage, setCurrentPage] = useState(0)
  const [isActive, setIsActive] = useState(false)

  const { loading, error, data } = useQuery(GET_AI_FEEDBACKS)
  // const { loading, error, data } = useQuery(GET_AI_FEEDBACKS, {
  //   variables: {
  //     first: FIRST,
  //     after: paginationState
  //   }
  // })

  return (
    <div className='mt-5'>
      {isShowAIResultModal && (
        <AIResultModal
          onExit={() => setIsShowAIResultModal(false)}
          aiResult={isShowAIResultModal}
        />
      )}
      {loading && <p>Loading...</p>}
      {error && <div> error... </div>}
      {data && (
        <>
          <div className='has-text-right mb-5'>
            <div className='control'>
              <div className='field is-narrow'>
                <div
                  className={clsx('dropdown is-right', {
                    'is-active': isActive
                  })}
                >
                  <div className='dropdown-trigger'>
                    <button
                      className='button is-primary'
                      disabled={data.admin.aiFeedbacks.edges.length === 0}
                      onClick={() => setIsActive(!isActive)}
                    >
                      <span>Download as</span>
                      <span className='icon is-small'>
                        <FontAwesomeIcon icon={faAngleDown} />
                      </span>
                    </button>
                  </div>
                  <div className='dropdown-menu'>
                    <div className='dropdown-content has-text-left'>
                      <a
                        className='dropdown-item'
                        href='#'
                        download='ai-feedbacks.json'
                        onClick={(e) => {
                          const url = window.URL.createObjectURL(
                            new Blob(
                              [
                                JSON.stringify(
                                  formatAIFeedbacks(
                                    data.admin.aiFeedbacks.edges
                                  )
                                )
                              ],
                              {
                                type: 'application/json'
                              }
                            )
                          )
                          e.target.href = url
                          setTimeout(
                            () => window.URL.revokeObjectURL(url),
                            30000
                          )
                        }}
                        target='_blank'
                        rel='noreferrer'
                      >
                        json
                      </a>
                      <a
                        className='dropdown-item'
                        href='#'
                        download='ai-feedbacks.csv'
                        onClick={(e) => {
                          const url = window.URL.createObjectURL(
                            new Blob(
                              [
                                Papa.unparse(
                                  formatAIFeedbacks(
                                    data.admin.aiFeedbacks.edges
                                  ),
                                  { quotes: true }
                                )
                              ],
                              {
                                type: 'text/csv'
                              }
                            )
                          )
                          e.target.href = url
                          setTimeout(
                            () => window.URL.revokeObjectURL(url),
                            30000
                          )
                        }}
                        target='_blank'
                        rel='noreferrer'
                      >
                        csv
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='table-container'>
            <table className='table is-fullwidth is-hoverable'>
              <thead>
                <tr>
                  <th className='is-narrow'>Created by</th>
                  <th className='is-narrow'>Created at</th>
                  <th className='is-narrow has-text-centered'>Result</th>
                  <th className='is-narrow has-text-centered'>Is correct?</th>
                  <th>Notes</th>
                  <th>Tags</th>
                  <th className='is-narrow has-text-centered'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.admin.aiFeedbacks.edges
                  .slice(currentPage * FIRST, (currentPage + 1) * FIRST)
                  .map(({ node: aiFeedback }) => (
                    <tr key={aiFeedback.id}>
                      <td className='is-narrow is-vcentered'>
                        {aiFeedback.caregiver.name}
                      </td>
                      <td className='is-narrow is-vcentered'>
                        {dayjs(aiFeedback.createdAt).format(
                          'DD-MM-YYYY HH:mm:ss'
                        )}
                      </td>
                      <td className='is-narrow has-text-centered is-vcentered'>
                        <FontAwesomeIcon
                          icon={faCircle}
                          size='xs'
                          className={clsx({
                            'has-text-success': !aiFeedback.aiResult.value,
                            'has-text-danger': aiFeedback.aiResult.value
                          })}
                        />
                      </td>
                      <td className='is-narrow has-text-centered is-vcentered'>
                        {aiFeedback.isCorrect ? (
                          <FontAwesomeIcon
                            icon={faCheck}
                            className='has-text-success'
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faXmark}
                            className='has-text-danger'
                          />
                        )}
                      </td>
                      <td
                        className='is-vcentered is-clickable'
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace:
                            expandedNotesId === aiFeedback.id
                              ? 'initial'
                              : 'nowrap',
                          maxWidth: '300px'
                        }}
                        onClick={() =>
                          setExpandedNotesId(
                            expandedNotesId ? null : aiFeedback.id
                          )
                        }
                      >
                        {aiFeedback.notes}
                      </td>
                      <td
                        className='is-vcentered is-clickable'
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace:
                            expandedNotesId === aiFeedback.id
                              ? 'initial'
                              : 'nowrap',
                          maxWidth: '300px'
                        }}
                        onClick={() =>
                          setExpandedNotesId(
                            expandedNotesId ? null : aiFeedback.id
                          )
                        }
                      >
                        {aiFeedback.tags.join(', ')}
                      </td>
                      <td className='is-narrow has-text-centered is-vcentered'>
                        <button
                          className='button is-text'
                          onClick={async () => {
                            setIsShowAIResultModal(aiFeedback.aiResult)
                          }}
                        >
                          Show result
                        </button>
                        <button
                          className='button is-text'
                          onClick={async () => {
                            window.open(
                              `/study/${aiFeedback.studyId}`,
                              '_blank'
                            )
                          }}
                        >
                          View study
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <nav className='pagination' role='navigation' aria-label='pagination'>
            <a
              className='pagination-previous'
              disabled={currentPage === 0}
              onClick={() => {
                if (currentPage === 0) return
                setCurrentPage(currentPage - 1)
              }}
            >
              Previous
            </a>
            <a
              className='pagination-next'
              disabled={
                (currentPage + 1) * FIRST >= data.admin.aiFeedbacks.edges.length
              }
              onClick={() => {
                if (
                  (currentPage + 1) * FIRST >=
                  data.admin.aiFeedbacks.edges.length
                )
                  return
                setCurrentPage(currentPage + 1)
              }}
            >
              Next
            </a>
          </nav>
          {/* <nav
            className='pagination is-centered'
            role='navigation'
            aria-label='pagination'
          >
            <a
              className='pagination-previous'
              disabled={!data.admin.aiFeedbacks.pageInfo.hasPreviousPage}
              onClick={() => {
                if (data.admin.aiFeedbacks.pageInfo.hasPreviousPage) {
                  dispatch(prev())
                }
              }}
            >
              Previous
            </a>
            <a
              className='pagination-next'
              disabled={!data.admin.aiFeedbacks.pageInfo.hasNextPage}
              onClick={() => {
                if (data.admin.aiFeedbacks.pageInfo.hasNextPage) {
                  dispatch(next(data.admin.aiFeedbacks.pageInfo.endCursor))
                }
              }}
            >
              Next
            </a>
          </nav> */}
        </>
      )}
    </div>
  )
}

export default AdminAIFeedbacks
