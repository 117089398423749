import { gql } from '@apollo/client'

export const GET_AUTH_DATA = gql`
  query GET_AUTH_DATA($id: ID!) {
    user(id: $id) {
      id # necessary for caching
      status
      firstName
      lastName
      settings {
        locale
      }
    }
    agent {
      country
    }
  }
`

export const GET_API_KEY = gql`
  query GET_API_KEY($id: ID!, $isGenerateNewIfNotExist: Boolean) {
    user(id: $id) {
      id # necessary for caching
      apiKey(isGenerateNewIfNotExist: $isGenerateNewIfNotExist) {
        id
        accessSecret
      }
    }
  }
`

export const GET_USER_DATA = gql`
  query GET_USER_DATA($id: ID!, $isAuthenticated: Boolean!) {
    user(id: $id) {
      id # necessary for caching
      email
      firstName
      middleName
      lastName
      name
      uId
      userType
      isAdmin
      settings {
        locale
        unitSystem
        dateFormat
        displayName
        viewerDefaultPaperSpeed
        viewerDefaultAmplitude
        viewerDefaultQRSIntervals
        viewerGridType
        viewerDisplayPredictiveMeasurement
        reportPaperSize
        reportHeader
        reportSignature
        reportConclusionTemplates
        reportDigitalSignature {
          service
          region
          bucket
          key
          type
          fileName
          size
        }
        showPlaybackControls
      }
      group
      clinic {
        id
        name
        phone
        logo {
          bucket
          key
        }
        address {
          country
          address1
          address2
          city
          region
          zipcode
        }
        paymentToken @include(if: $isAuthenticated) {
          id
          creditCard {
            number
            type
            month
            year
            name
          }
        }
        referralsQueues(isAdmin: true) @include(if: $isAuthenticated) {
          id
        }
        targetReferralsTotal(status: Pending) @include(if: $isAuthenticated)
        referralsQueuesReferralsTotal(
          isAdmin: false
          status: PendingInQueue
          queueType: AssignmentAdminsAndCaregivers
        ) @include(if: $isAuthenticated)
        servicePlans {
          id
          active
          provider
        }
        authorizedActions {
          allow
          deny
        }
      }
      isTargetForReferrals @include(if: $isAuthenticated)
      authorizedActions {
        allow
        deny
      }
    }
  }
`

export const GET_SERVICE_PLAN_CHECKOUT_PAGE = gql`
  query GET_SERVICE_PLAN_CHECKOUT_PAGE(
    $id: ID!
    $clinicId: ID!
    $returnUrl: AWSURL!
  ) {
    servicePlan(id: $id) {
      checkoutPage(clinicId: $clinicId, returnUrl: $returnUrl)
    }
  }
`

export const GET_SERVICE_PLAN_CHANGE_CC_PAGE = gql`
  query GET_SERVICE_PLAN_CHANGE_CC_PAGE($id: ID!, $clinicId: ID!) {
    servicePlan(id: $id) {
      changeCCPage(clinicId: $clinicId)
    }
  }
`

export const GET_USERS_LIST_FROM_USER_INPUT = gql`
  query GET_USERS_LIST_FROM_USER_INPUT($text: String!) {
    admin {
      id # necessary for caching
      userSearch(text: $text) {
        id
        email
        name
      }
    }
  }
`

export const GET_ADMIN_DATA_FROM_USER_EMAIL = gql`
  query GET_ADMIN_DATA_FROM_USER_EMAIL($email: AWSEmail!) {
    admin {
      id # necessary for caching
      userByEmail(email: $email) {
        id
        email
        firstName
        middleName
        lastName
        name
        uId
        userType
        isCardiologist
        isAdmin
        isDemo
        isMigratedFromLegacyApp
        isLoggedInToNewWebApp
        createdAt
        isTargetForReferrals
        status
        settings {
          locale
          unitSystem
          dateFormat
          displayName
          viewerDefaultPaperSpeed
          viewerDefaultAmplitude
          viewerDefaultQRSIntervals
          viewerGridType
          viewerDisplayPredictiveMeasurement
          reportPaperSize
          reportHeader
          reportConclusionTemplates
          reportSignature
          reportDigitalSignature {
            service
            region
            bucket
            key
            type
            fileName
            size
          }
          isAllowPrintOnMobile
          showPlaybackControls
        }
        group
        questionnaireTemplate {
          id
        }
        clinic {
          id
          name
          phone
          servicePlans {
            id
            createdAt
            provider
            product {
              name
            }
            contract {
              name
              price
              currency
              nextBillingDate
            }
            paymentToken {
              creditCard {
                type
                number
                month
                year
              }
            }
            payer
            autoRenew
            active
            clinics {
              owner {
                email
                settings {
                  displayName
                }
              }
            }
          }
          logo {
            bucket
            key
          }
          address {
            country
          }
          devices {
            id
            deviceClass
            deviceNumber
            attachedAt
            numOfStudies
            clinic {
              id
            }
          }
          aggregations {
            studies
            patients
          }
        }
      }
    }
  }
`

export const GET_NON_ACTIVE_USERS = gql`
  query GET_NON_ACTIVE_USERS {
    admin {
      id # necessary for caching
      nonActiveUsers {
        createdAt
        id
        email
      }
    }
  }
`

export const GET_NON_CONFIGURED_USERS = gql`
  query GET_NON_CONFIGURED_USERS($first: Int, $before: String, $after: String) {
    admin {
      id # necessary for caching
      nonConfiguredUsers(first: $first, before: $before, after: $after) {
        edges {
          node {
            id
            createdAt
            firstName
            lastName
            email
            isCardiologist
            userType
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }
    }
  }
`

export const GET_REFERRALS_BY_STATUS = gql`
  query GET_REFERRALS_BY_STATUS(
    $status: ReferralStatus
    $first: Int
    $before: String
    $after: String
  ) {
    admin {
      id # necessary for caching
      referralsByStatus(
        status: $status
        first: $first
        before: $before
        after: $after
      ) {
        edges {
          node {
            id
            createdAt
            status
            referralType
            message
            currency
            price
            studyId
            isTargetIsQueue
            sourceClinic {
              id
              name
            }
            sourceCaregiver {
              id
              name
              email
            }
            targetClinic {
              id
              name
            }
            targetCaregiver {
              id
              name
              email
            }
            patient {
              id
              name
              owner
            }
            targetQueue {
              id
              name
            }
            aiResult {
              id
              value
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }
    }
  }
`
export const GET_SERVICE_PLANS = gql`
  query GET_SERVICE_PLANS($first: Int, $before: String, $after: String) {
    admin {
      id # necessary for caching
      servicePlans(first: $first, before: $before, after: $after) {
        edges {
          node {
            id
            createdAt
            product {
              name
            }
            active
            autoRenew
            payer
            contract {
              name
              price
              currency
              nextBillingDate
            }
            paymentToken {
              creditCard {
                type
                number
                month
                year
              }
            }
            clinics {
              owner {
                email
                settings {
                  displayName
                }
              }
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }
    }
  }
`

export const GET_MOBILE_APP_LOGS = gql`
  query GET_MOBILE_APP_LOGS(
    $type: MobileAppLogType
    $first: Int
    $before: String
    $after: String
  ) {
    admin {
      id # necessary for caching
      mobileAppLogs(
        type: $type
        first: $first
        before: $before
        after: $after
      ) {
        edges {
          node {
            id
            timestamp
            appVersion
            device
            email
            issueInLogTime
            description
            issueTime
            key
            name
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }
    }
  }
`

export const GET_MOBILE_APP_LOG_LINK = gql`
  query GET_MOBILE_APP_LOG_LINK($key: String!) {
    admin {
      id # necessary for caching
      mobileAppLogsLink(key: $key)
    }
  }
`

export const GET_PENDING_TRANSACTIONS_BY_TYPE = gql`
  query GET_PENDING_TRANSACTIONS_BY_TYPE(
    $type: TransactionType
    $first: Int
    $before: String
    $after: String
  ) {
    admin {
      id # necessary for caching
      pendingTransactionsByType(
        type: $type
        first: $first
        before: $before
        after: $after
      ) {
        edges {
          node {
            id
            createdAt
            data
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }
    }
  }
`

export const GET_AI_FEEDBACKS = gql`
  query GET_AI_FEEDBACKS($first: Int, $before: String, $after: String) {
    admin {
      id # necessary for caching
      aiFeedbacks(first: $first, before: $before, after: $after) {
        edges {
          node {
            id
            createdAt
            studyId
            aiResult {
              id
              value
            }
            isCorrect
            notes
            tags
            caregiver {
              name
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }
    }
  }
`

export const GET_LOG_BY_LOG_GROUP_ID = gql`
  query GET_LOG_BY_LOG_GROUP_ID($logGroupId: ID!, $first: Int, $after: String) {
    admin {
      id # necessary for caching
      logByLogGroupId(logGroupId: $logGroupId, first: $first, after: $after) {
        edges {
          node {
            id
            email
            createdAt
            event
            url
            data
            requestOrigin
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

export const GET_REFERRALS_QUEUES = gql`
  query GET_REFERRALS_QUEUES {
    referralsQueues {
      id
      name
    }
  }
`

export const GET_REFERRALS_QUEUES_CONFIG = gql`
  query GET_REFERRALS_QUEUES_CONFIG {
    referralsQueues {
      id
      name
      queueType
      queue {
        admins {
          id
          name
          email
        }
        caregivers {
          id
          name
          email
          referralType
        }
      }
    }
  }
`

export const GET_REFERRALS_CONFIGS = gql`
  query GET_REFERRALS_CONFIGS {
    referralsConfigs {
      id
    }
  }
`

export const GET_REFERRALS_CONFIG = gql`
  query GET_REFERRALS_CONFIG($referralsConfigId: ID!) {
    referralsConfig(referralsConfigId: $referralsConfigId) {
      id # necessary for caching
      caregiver {
        id
        name
        email
      }
      studyProtocol
      referralType
      currency
      price
      unavailableUntil
      isQueue
      order
    }
  }
`

export const GET_REFERRALS_CONFIGS_WITH_DATA = gql`
  query GET_REFERRALS_CONFIGS_WITH_DATA {
    referralsConfigs {
      id
      config {
        id
        caregiver {
          id
          name
          email
        }
        studyProtocol
        referralType
        currency
        price
        unavailableUntil
        isQueue
        order
      }
    }
  }
`

export const GET_QUESTIONNAIRE_TEMPLATES = gql`
  query GET_QUESTIONNAIRE_TEMPLATES {
    questionnaireTemplates {
      id
      template {
        type
        title
        value
        options
      }
      isForced
      isCanBeStandaloneStudy
    }
  }
`

export const GET_USER_AUDITS = gql`
  query GET_USER_AUDITS($id: ID!, $first: Int, $after: String) {
    user(id: $id) {
      id # necessary for caching
      auditStream(first: $first, after: $after) {
        edges {
          node {
            id
            createdAt
            persistedAt
            event
            data
            creator
            url
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

export const GET_USER_LOG = gql`
  query GET_USER_LOG($id: ID!, $first: Int, $after: String) {
    user(id: $id) {
      id # necessary for caching
      logStream(first: $first, after: $after) {
        edges {
          node {
            id
            email
            createdAt
            event
            url
            logsGroupId
            data
            requestOrigin
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

export const GET_USER_ACTIVITY_LOG = gql`
  query GET_USER_ACTIVITY_LOG($id: ID!, $first: Int, $after: String) {
    user(id: $id) {
      id # necessary for caching
      activityLog(first: $first, after: $after) {
        edges {
          node {
            id
            email
            createdAt
            event
            url
            logsGroupId
            data
            requestOrigin
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

export const GET_CLINIC_AUDITS = gql`
  query GET_CLINIC_AUDITS($id: ID!, $first: Int, $after: String) {
    clinic(id: $id) {
      id # necessary for caching
      auditStream(first: $first, after: $after) {
        edges {
          node {
            id
            createdAt
            persistedAt
            event
            data
            creator
            url
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

export const GET_CLINIC_LOG = gql`
  query GET_CLINIC_LOG($id: ID!, $first: Int, $after: String) {
    clinic(id: $id) {
      id # necessary for caching
      logStream(first: $first, after: $after) {
        edges {
          node {
            id
            email
            createdAt
            event
            url
            logsGroupId
            data
            requestOrigin
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

export const GET_USER_ACTIVITY_AUDIT = gql`
  query GET_USER_ACTIVITY_AUDIT($id: ID!, $first: Int, $after: String) {
    user(id: $id) {
      id # necessary for caching
      activityAudit(first: $first, after: $after) {
        edges {
          node {
            id
            createdAt
            persistedAt
            event
            data
            creator
            url
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

export const GET_CLINIC_REFERRALS = gql`
  query GET_CLINIC_REFERRALS(
    $id: ID!
    $first: Int
    $sourceBefore: String
    $sourceAfter: String
    $targetBefore: String
    $targetAfter: String
  ) {
    clinic(id: $id) {
      id # necessary for caching
      sourceReferrals(
        first: $first
        before: $sourceBefore
        after: $sourceAfter
      ) {
        edges {
          node {
            id
            createdAt
            status
            referralType
            message
            currency
            price
            studyId
            isTargetIsQueue
            targetQueue {
              id
              name
            }
            sourceClinic {
              id
              name
            }
            sourceCaregiver {
              id
              name
              email
            }
            targetClinic {
              id
              name
            }
            targetCaregiver {
              id
              name
              email
            }
            patient {
              id
              name
              owner
            }
            aiResult {
              id
              value
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }
      targetReferrals(
        first: $first
        before: $targetBefore
        after: $targetAfter
      ) {
        edges {
          node {
            id
            createdAt
            status
            referralType
            message
            currency
            price
            studyId
            isTargetIsQueue
            targetQueue {
              id
              name
            }
            sourceClinic {
              id
              name
            }
            sourceCaregiver {
              id
              name
              email
            }
            targetClinic {
              id
              name
            }
            targetCaregiver {
              id
              name
              email
            }
            patient {
              id
              name
              owner
            }
            aiResult {
              id
              value
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }
    }
  }
`

export const GET_STUDIES = gql`
  query GET_STUDIES(
    $id: ID!
    $patientId: ID!
    $protocol: StudyProtocol
    $tag: String
    $first: Int
    $before: String
    $after: String
  ) {
    clinic(id: $id) {
      id # necessary for caching
      studies(
        patientId: $patientId
        protocol: $protocol
        tag: $tag
        first: $first
        before: $before
        after: $after
      ) {
        edges {
          node {
            id
            studyId
            protocol
            studyCreatedAt
            timezoneOffset
            duration
            hasReport
            shareType
            isSeen
            aiResult {
              id
              value
            }
            sourceCaregiver {
              id
              name
              email
            }
            patient {
              id
              name
              owner
              externalId
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }
    }
  }
`

export const GET_PATIENTS_SEARCH = gql`
  query GET_PATIENTS_SEARCH($id: ID!, $prefix: String, $first: Int) {
    clinic(id: $id) {
      id
      patientsSearch(prefix: $prefix, first: $first) {
        id
        name
        owner
        externalId
        lastStudyDate
        lastStudyDateTimezoneOffset
        sharedBy
      }
    }
  }
`

export const GET_TAGS = gql`
  query GET_TAGS($id: ID!, $prefix: String, $first: Int, $after: String) {
    clinic(id: $id) {
      id
      tags(prefix: $prefix, first: $first, after: $after) {
        edges {
          node {
            id
          }
        }
        pageInfo {
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }
    }
  }
`

export const GET_REPORTS = gql`
  query GET_REPORTS(
    $id: ID!
    $patientId: ID
    $first: Int
    $before: String
    $after: String
  ) {
    clinic(id: $id) {
      id
      sourceReferrals(
        patientId: $patientId
        first: $first
        before: $before
        after: $after
      ) {
        edges {
          node {
            id
            studyId
            reportId
            createdAt
            patient {
              id
              name
              owner
            }
            sourceCaregiver {
              id
              name
              email
            }
            targetCaregiver {
              id
              name
              email
            }
            targetQueue {
              id
              name
            }
            status
            referralType
            price
            aiResult {
              id
              value
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }
    }
  }
`

export const GET_REFERRALS = gql`
  query GET_REFERRALS(
    $id: ID!
    $status: ReferralStatus
    $queuesReferralsStartStatus: ReferralStatus
    $queuesReferralsEndStatus: ReferralStatus
    $first: Int
  ) {
    clinic(id: $id) {
      id
      targetReferrals(status: $status, first: $first) {
        edges {
          node {
            id
            studyId
            createdAt
            patient {
              id
              name
              owner
            }
            sourceCaregiver {
              id
              name
              email
            }
            targetCaregiver {
              id
              name
              email
            }
            targetQueue {
              id
              name
            }
            status
            referralType
            price
            aiResult {
              id
              value
            }
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }
      referralsQueues(
        isAdmin: false
        queueType: AssignmentAdminsAndCaregivers
      ) {
        referrals(
          startStatus: $queuesReferralsStartStatus
          endStatus: $queuesReferralsEndStatus
          first: $first
        ) {
          edges {
            node {
              id
              studyId
              createdAt
              patient {
                id
                name
                owner
              }
              sourceCaregiver {
                id
                name
                email
              }
              targetQueue {
                id
                name
              }
              status
              referralType
              isTargetIsQueue
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
          }
        }
      }
    }
  }
`

export const GET_CLINIC_QUEUES_REFERRALS = gql`
  query GET_CLINIC_QUEUES_REFERRALS(
    $id: ID!
    $startStatus: ReferralStatus
    $endStatus: ReferralStatus
    $first: Int
  ) {
    clinic(id: $id) {
      id
      referralsQueues(isAdmin: true) {
        id
        name
        caregivers {
          id
          name
          email
          referralType
          clinic {
            id
            name
          }
        }
        referrals(
          startStatus: $startStatus
          endStatus: $endStatus
          first: $first
        ) {
          edges {
            node {
              id
              studyId
              createdAt
              patient {
                id
                name
                owner
              }
              sourceCaregiver {
                id
                name
                email
              }
              targetCaregiver {
                id
                name
                email
              }
              targetClinic {
                id
              }
              targetQueue {
                id
                name
              }
              status
              referralType
              price
              isTargetIsQueue
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasPreviousPage
            hasNextPage
          }
        }
      }
    }
  }
`
export const GET_QUEUE_MEMBERS = gql`
  query GET_QUEUE_MEMBERS($id: ID!) {
    referralsQueue(id: $id) {
      id
      name
      caregivers {
        id
        name
        email
        referralType
        clinic {
          id
          name
        }
      }
    }
  }
`

export const GET_SHARES_AUTO = gql`
  query GET_SHARES_AUTO(
    $id: ID!
    $isAuthorizedSourceSharesAuto: Boolean!
    $isAuthorizedTargetSharesAuto: Boolean!
  ) {
    clinic(id: $id) {
      id
      sourceSharesAuto @include(if: $isAuthorizedSourceSharesAuto) {
        edges {
          node {
            id
            status
            email
            targetCaregiver {
              id
              name
              email
            }
            targetClinic {
              id
              name
            }
          }
        }
      }
      targetSharesAuto @include(if: $isAuthorizedTargetSharesAuto) {
        edges {
          node {
            id
            status
            sourceCaregiver {
              id
              name
              email
            }
            sourceClinic {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const GET_SHARES_MANUAL = gql`
  query GET_SHARES_MANUAL($id: ID!) {
    clinic(id: $id) {
      id
      targetSharesManual {
        edges {
          node {
            id
            targetCaregiver {
              id
              name
              email
            }
            targetClinic {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const GET_STUDY_DATA = gql`
  query GET_STUDY_DATA(
    $id: ID!
    $clinicId: ID!
    $referralsConfigId: ID
    $hasGroup: Boolean!
  ) {
    study(id: $id) {
      id # necessary for caching
      createdAt
      timezoneOffset
      duration
      protocol
      hasReport
      hasEvents
      anonymousPatientData {
        unitSystem
        gender
        weight
        height
        age
        ageUnits
        anamnesis
        icdCode
        animal {
          type
          breed
          castrated
        }
      }
      caregiver {
        id
        userType
        settings {
          displayName
          reportHeader
          reportSignature
        }
      }
      clinic {
        id
        name
        logo {
          bucket
          key
        }
        address {
          country
          address1
          address2
        }
        phone
      }
      patient {
        id
        name
        email
        phone
        externalId
        anamnesis
        gender
        weight
        birthdate
        animal {
          type
          breed
          owner
        }
        studies(clinicId: $clinicId) {
          edges {
            node {
              id
              studyId
              studyCreatedAt
              timezoneOffset
              duration
            }
          }
        }
      }
      records {
        __typename
        id
        ... on RecordEcgRestData {
          file {
            url
          }
        }
        ... on RecordEcgHolterData {
          file {
            bucket
            key
            url
          }
        }
        ... on RecordStethoscopeData {
          file {
            url
          }
        }
        ... on RecordSpirometerData {
          file {
            url
          }
        }
        ... on RecordQuestionnaireData {
          items {
            type
            title
            value
            options
          }
        }
        ... on RecordAttachmentData {
          id
          createdAt
          timezoneOffset
          caregiver {
            id
            name
          }
          file {
            bucket
            key
            fileName
          }
          group {
            id
            title
            description
          }
        }
      }
      measurements(clinicId: $clinicId) {
        id
        measurements
      }
      userMarks(clinicId: $clinicId) {
        id
        userMarks
      }
      diagnosis(clinicId: $clinicId) {
        id
        updatedAt
        measurements
        mea
        conclusion
        tags
        caregiver {
          name
        }
      }
      aiResult {
        id
        value
      }
      aiFeedback(clinicId: $clinicId) {
        id
        updatedAt
        isCorrect
        notes
        tags
        caregiver {
          name
        }
      }
      comments {
        id
        createdAt
        text
        caregiver {
          name
        }
      }
      reports {
        id
        updatedAt
        state
        caregiver {
          name
        }
      }
      referrals {
        id
        studyId
        createdAt
        message
        status
        referralType
        price
        targetCaregiver {
          id
          name
          email
        }
        patient {
          id
          name
          owner
        }
        sourceCaregiver {
          id
          name
          email
        }
        targetQueue {
          id
          name
        }
        authorizedActionsWrapper {
          authorizedActions {
            allow
            deny
          }
        }
      }
      referralsConfig(referralsConfigId: $referralsConfigId)
        @include(if: $hasGroup) {
        id
        caregiver {
          id
          name
          email
        }
        clinic {
          id
          name
        }
        referralType
        isQueue
        currency
        price
        unavailableUntil
        order
      }
      isSeen(clinicId: $clinicId)
      authorizedActions {
        allow
        deny
      }
    }
  }
`

export const GET_AI_RESULT = gql`
  query GET_AI_RESULT($id: ID!) {
    aiResult(id: $id) {
      id
      studyId
      recordId
      createdAt
      data
    }
  }
`

export const GET_STUDY_AUTHORIZED_ACTIONS = gql`
  query GET_STUDY_AUTHORIZED_ACTIONS($id: ID!) {
    study(id: $id) {
      id # necessary for caching
      authorizedActions {
        allow
        deny
      }
    }
  }
`

export const GET_STUDY_REPORT = gql`
  query GET_STUDY_REPORT($id: ID!) {
    study(id: $id) {
      id # necessary for caching
      reports {
        id
        privatePdf {
          key
        }
        publicPdf {
          key
        }
      }
    }
  }
`
export const GET_INVOICE_PAGE = gql`
  query GET_INVOICE_PAGE($id: ID!) {
    referral(id: $id) {
      invoicePage
    }
  }
`

export const GET_STUDY_AUDITS = gql`
  query GET_STUDY_AUDITS($id: ID!, $first: Int, $after: String) {
    study(id: $id) {
      id # necessary for caching
      auditStream(first: $first, after: $after) {
        edges {
          node {
            id
            createdAt
            persistedAt
            event
            data
            creator
            name
            email
            url
            requestHeaders
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

export const GET_STUDY_LOG = gql`
  query GET_STUDY_LOG($id: ID!, $first: Int, $after: String) {
    study(id: $id) {
      id # necessary for caching
      logStream(first: $first, after: $after) {
        edges {
          node {
            id
            email
            createdAt
            event
            url
            logsGroupId
            data
            requestOrigin
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`

export const GET_REPORT_DATA = gql`
  query GET_REPORT_DATA($reportId: ID!, $hasReport: Boolean!) {
    report(id: $reportId) @include(if: $hasReport) {
      id
      conclusion
      measurements
      state
      mea
      updatedAt
      caregiver {
        id
        settings {
          displayName
          reportSignature
        }
      }
      signature
      digitalSignature {
        bucket
        key
      }
      authorizedActions {
        allow
        deny
      }
    }
  }
`

export const GET_PRIVATE_REPORT_PDF = gql`
  query GET_PRIVATE_REPORT_PDF($reportId: ID!) {
    report(id: $reportId) {
      id # necessary for caching
      signedPrivatePdf
    }
  }
`

export const GET_PUBLIC_REPORT_PDF = gql`
  query GET_PUBLIC_REPORT_PDF($reportId: ID!) {
    report(id: $reportId) {
      id # necessary for caching
      signedPublicPdf
    }
  }
`

export const GET_STUDY_SHARES = gql`
  query GET_STUDY_SHARES($studyId: ID!) {
    study(id: $studyId) {
      id # necessary for caching
      shares {
        id
        email
        targetCaregiver {
          id
          name
          email
        }
        targetClinic {
          name
        }
        shareType
      }
    }
  }
`

export const GET_DEVICE = gql`
  query GET_DEVICE($deviceId: ID!) {
    device(id: $deviceId) {
      id # necessary for caching
      deviceClass
      deviceNumber
      attachedAt
      numOfStudies
      clinic {
        id
        owner {
          email
          settings {
            displayName
          }
        }
      }
    }
  }
`
