import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/gatsby'
import AuthContext from './AuthContext'
import App from './App'
import CreateAccount from './CreateAccount'
import Loader from './Loader'
import { GET_AUTH_DATA, GET_USER_DATA } from './queries'
import { ANONYMOUS_USER_ID } from '../../utils/utils'
import { useLocalization } from './LocalizationProvider'
import { useAdmin } from './AdminProvider'

import './all.scss'

const AppWrapper = (props) => {
  const { setLocaleKey, locale: defaultLocale } = useLocalization()
  const { setCurrentAdmin, viewAsUser } = useAdmin()

  const potentialId =
    (viewAsUser && viewAsUser.id) ||
    (props.authData && props.authData.attributes
      ? props.authData.attributes.preferred_username || // the preferred_username is the legacy id (UsrXXXXXXXX) and we need to prioritize it for migration reasons
        props.authData.attributes.sub
      : window.currentUserId || ANONYMOUS_USER_ID) // window.currentUserId is being injected by generatePdfReport lambda so it will be called in the context of the calling user
  const relevantPath =
    props.authState === 'signedIn' || props.authState === 'publicPath'
  const {
    loading: authLoading,
    error: authError,
    data: authData
  } = useQuery(GET_AUTH_DATA, {
    variables: {
      id: potentialId
    },
    onCompleted: (authData) => {
      if (
        authData &&
        authData.user &&
        authData.user.settings &&
        authData.user.settings.locale
      ) {
        window.AUTH_CONFIG.clientMetadata.locale = authData.user.settings.locale
        setLocaleKey(authData.user.settings.locale)
      }
    },
    skip: !relevantPath
  })
  const {
    loading: userLoading,
    error: userError,
    data: userData
  } = useQuery(GET_USER_DATA, {
    variables: {
      id: potentialId,
      isAuthenticated: potentialId !== ANONYMOUS_USER_ID
    },
    onCompleted: (userData) => {
      if (userData && userData.user && userData.user.settings.locale) {
        window.AUTH_CONFIG.clientMetadata.locale = userData.user.settings.locale
        setLocaleKey(userData.user.settings.locale)
      }
      if (userData && userData.user && userData.user.isAdmin) {
        setCurrentAdmin(userData.user)
      }
    },
    skip: !relevantPath || !authData || authData.user.status === 'Registered'
  })

  useEffect(() => {
    document.title = defaultLocale.cardiac_analysis
  }, [defaultLocale])

  useEffect(() => {
    if (userData && userData.user) {
      Sentry.setUser({
        id: userData.user.id,
        email: userData.user.email
      })
    } else {
      Sentry.setUser(null)
    }
  }, [userData])
  if (!relevantPath) return null
  if (authError || userError) {
    if (
      authError &&
      authError.networkError &&
      authError.networkError.result &&
      Array.isArray(authError.networkError.result.errors) &&
      authError.networkError.result.errors.length > 0 &&
      authError.networkError.result.errors[0].errorType ===
        'BadRequestException' &&
      authError.networkError.result.errors[0].message &&
      (authError.networkError.result.errors[0].message.indexOf(
        'Signature expired'
      ) ||
        authError.networkError.result.errors[0].message.indexOf(
          'Signature not yet current'
        )) &&
      authError.networkError.result.errors[0].message.indexOf('5 min.')
    ) {
      return (
        <div
          className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center has-text-weight-bold is-size-5'
          style={{ height: '100%' }}
        >
          <p style={{ whiteSpace: 'pre-line' }}>
            {defaultLocale.clock_out_of_sync}
          </p>
        </div>
      )
    }
    return (
      <div
        className='is-flex is-flex-direction-column is-justify-content-center is-align-items-center has-text-weight-bold is-size-5'
        style={{ height: '100%' }}
      >
        <p style={{ whiteSpace: 'pre-line' }}>
          {authError
            ? `Auth Error: ${authError.toString()}`
            : `User Error: ${userError.toString()}`}
        </p>
      </div>
    )
  }
  if (authLoading || userLoading) return <Loader />
  const {
    user: {
      id,
      status,
      firstName,
      lastName,
      settings: { locale }
    },
    agent: { country }
  } = authData
  if (status === 'Registered') {
    return (
      <CreateAccount
        id={id}
        firstName={firstName !== ANONYMOUS_USER_ID ? firstName : undefined}
        lastName={lastName !== ANONYMOUS_USER_ID ? lastName : undefined}
        country={country}
        locale={locale}
      />
    )
  }

  return (
    <AuthContext.Provider value={userData.user}>
      <App gotoLoginPage={() => props.onStateChange('signIn', null)} />
    </AuthContext.Provider>
  )
}

export default AppWrapper
