import React, { useEffect, useContext, useState } from 'react'
import { GET_CLINIC_REFERRALS } from '../queries'
import { useLazyQuery } from '@apollo/client'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import useMemoryPagination, {
  prev,
  next,
  init
} from '../../hooks/useMemoryPagination'
import AuthContext from '../AuthContext'
import { DATE_FORMAT_CONVERTER } from '../../../utils/utils'
import dayjs from 'dayjs'
import ReferralCancel from '../ReferralCancel'
import { useLocalization } from '../LocalizationProvider'
import { REFERRAL_TYPES_CONVERTER } from '../../../utils/utils'
import AdminAssignQueueReferral from './AdminAssignQueueReferral'

const FIRST = 20

const AdminUserReferrals = ({ clinicId }) => {
  const currentUser = useContext(AuthContext)
  const [getReferrals, { loading, error, data }] =
    useLazyQuery(GET_CLINIC_REFERRALS)
  const [sourcePaginationState, dispatchSource] = useMemoryPagination()
  const [targetPaginationState, dispatchTarget] = useMemoryPagination()
  const [referralToCancel, setReferralToCancel] = useState(null)
  const [queueReferralToAssign, setQueueReferralToAssign] = useState(null)
  const { locale } = useLocalization()

  function doGetReferrals(showInit) {
    showInit && dispatchSource(init())
    showInit && dispatchTarget(init())
    getReferrals({
      variables: {
        id: clinicId,
        first: FIRST,
        sourceAfter: sourcePaginationState,
        targetAfter: targetPaginationState
      }
    })
  }

  useEffect(() => {
    clinicId && doGetReferrals(true)
  }, [clinicId])

  useEffect(() => {
    clinicId && doGetReferrals(false)
  }, [sourcePaginationState, targetPaginationState])

  return (
    <div>
      {referralToCancel && (
        <ReferralCancel
          referral={referralToCancel}
          onComplete={() => {
            setReferralToCancel(null)
            alert('Referral is canceled, refresh the page to see the result')
          }}
          onExit={() => setReferralToCancel(null)}
        ></ReferralCancel>
      )}
      {queueReferralToAssign && (
        <AdminAssignQueueReferral
          referral={queueReferralToAssign}
          onComplete={() => {
            setQueueReferralToAssign(null)
          }}
          onExit={() => setQueueReferralToAssign(null)}
        ></AdminAssignQueueReferral>
      )}

      {loading && <p>Loading...</p>}
      {error && <div> error... </div>}

      {data &&
        data.clinic.sourceReferrals.edges.length === 0 &&
        data.clinic.targetReferrals.edges.length === 0 && (
          <h6 className='title is-6'>No referrals</h6>
        )}

      {data && data.clinic.sourceReferrals.edges.length > 0 && (
        <div>
          <h6 className='title is-6'>User requested referrals</h6>
          <table className='table is-fullwidth is-hoverable'>
            <thead>
              <tr>
                <th>Created at</th>
                <th>Type</th>
                <th>Status</th>
                <th>Ordered from</th>
                <th>Message</th>
                <th>Price</th>
                <th>Currency</th>
                <th className='has-text-centered'>AI</th>
                <th>Cancel</th>
                <th>Assign</th>
              </tr>
            </thead>
            <tbody>
              {data.clinic.sourceReferrals.edges.map(({ node: referral }) => (
                <tr
                  key={referral.id}
                  className='c-hand'
                  onClick={async () =>
                    window.open(`/study/${referral.studyId}`, '_blank')
                  }
                >
                  <td>
                    {dayjs(referral.createdAt).format(
                      `${
                        DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
                      } HH:mm:ss`
                    )}
                  </td>
                  <td>
                    {
                      locale.studies.reports[
                        REFERRAL_TYPES_CONVERTER[referral.referralType]
                      ]
                    }
                  </td>
                  <td>{referral.status}</td>
                  <td>
                    {referral.isTargetIsQueue
                      ? referral.targetQueue.name
                      : `${referral.targetCaregiver.name} (${referral.targetCaregiver.email})`}
                  </td>
                  <td>{referral.message}</td>
                  <td>{referral.price}</td>
                  <td>{referral.currency}</td>
                  <td className='has-text-centered is-vcentered'>
                    {referral.aiResult && (
                      <FontAwesomeIcon
                        icon={faCircle}
                        size='xs'
                        className={clsx({
                          'has-text-success': !referral.aiResult.value,
                          'has-text-danger': referral.aiResult.value
                        })}
                      />
                    )}
                  </td>
                  <td>
                    {referral.status != 'Done' && (
                      <button
                        className='button'
                        onClick={(e) => {
                          e.stopPropagation()
                          setReferralToCancel(referral)
                        }}
                      >
                        Cancel
                      </button>
                    )}
                  </td>
                  <td>
                    {referral.targetQueue !== null && (
                      <button
                        className='button'
                        onClick={(e) => {
                          e.stopPropagation()
                          setQueueReferralToAssign(referral)
                        }}
                      >
                        Assign
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <nav className='pagination' role='navigation' aria-label='pagination'>
            <a
              className='pagination-previous'
              disabled={!data.clinic.sourceReferrals.pageInfo.hasPreviousPage}
              onClick={() => {
                if (data.clinic.sourceReferrals.pageInfo.hasPreviousPage) {
                  dispatchSource(prev())
                }
              }}
            >
              Previous
            </a>
            <a
              className='pagination-next'
              disabled={!data.clinic.sourceReferrals.pageInfo.hasNextPage}
              onClick={() => {
                if (data.clinic.sourceReferrals.pageInfo.hasNextPage) {
                  dispatchSource(
                    next(data.clinic.sourceReferrals.pageInfo.endCursor)
                  )
                }
              }}
            >
              Next
            </a>
          </nav>
        </div>
      )}
      {data &&
        data.clinic.sourceReferrals.edges.length > 0 &&
        data.clinic.targetReferrals.edges.length > 0 && (
          <div className='my-5'></div>
        )}
      {data && data.clinic.targetReferrals.edges.length > 0 && (
        <div>
          <h6 className='title is-6'>Requested referrals from user</h6>
          <table className='table is-fullwidth is-hoverable'>
            <thead>
              <tr>
                <th>Created at</th>
                <th>Type</th>
                <th>Status</th>
                <th>Ordered by</th>
                <th>Message</th>
                <th>Price</th>
                <th>Currency</th>
                <th className='has-text-centered'>AI</th>
                <th>Cancel</th>
                <th>Assign</th>
              </tr>
            </thead>
            <tbody>
              {data.clinic.targetReferrals.edges.map(({ node: referral }) => (
                <tr
                  key={referral.id}
                  className='c-hand'
                  onClick={async () =>
                    window.open(`/study/${referral.studyId}`, '_blank')
                  }
                >
                  <td>
                    {dayjs(referral.createdAt).format(
                      `${
                        DATE_FORMAT_CONVERTER[currentUser.settings.dateFormat]
                      } HH:mm:ss`
                    )}
                  </td>
                  <td>{referral.referralType}</td>
                  <td>{referral.status}</td>
                  <td>{`${referral.sourceCaregiver.name} (${referral.sourceCaregiver.email})`}</td>
                  <td>{referral.message}</td>
                  <td>{referral.price}</td>
                  <td>{referral.currency}</td>
                  <td className='has-text-centered is-vcentered'>
                    {referral.aiResult && (
                      <FontAwesomeIcon
                        icon={faCircle}
                        size='xs'
                        className={clsx({
                          'has-text-success': !referral.aiResult.value,
                          'has-text-danger': referral.aiResult.value
                        })}
                      />
                    )}
                  </td>
                  <td>
                    {referral.status != 'Done' && (
                      <button
                        className='button'
                        onClick={(e) => {
                          e.stopPropagation()
                          setReferralToCancel(referral)
                        }}
                      >
                        Cancel
                      </button>
                    )}
                  </td>
                  <td>
                    {referral.targetQueue !== null && (
                      <button
                        className='button'
                        onClick={(e) => {
                          e.stopPropagation()
                          setQueueReferralToAssign(referral)
                        }}
                      >
                        Assign
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <nav className='pagination' role='navigation' aria-label='pagination'>
            <a
              className='pagination-previous'
              disabled={!data.clinic.targetReferrals.pageInfo.hasPreviousPage}
              onClick={() => {
                if (data.clinic.targetReferrals.pageInfo.hasPreviousPage) {
                  dispatchTarget(prev())
                }
              }}
            >
              Previous
            </a>
            <a
              className='pagination-next'
              disabled={!data.clinic.targetReferrals.pageInfo.hasNextPage}
              onClick={() => {
                if (data.clinic.targetReferrals.pageInfo.hasNextPage) {
                  dispatchTarget(
                    next(data.clinic.targetReferrals.pageInfo.endCursor)
                  )
                }
              }}
            >
              Next
            </a>
          </nav>
        </div>
      )}
    </div>
  )
}

export default AdminUserReferrals
