import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useLocalization } from '../LocalizationProvider'
import Loader from '../Loader'
import { GET_REFERRALS_CONFIGS_WITH_DATA } from '../queries'
import { REFERRAL_TYPES_CONVERTER } from '../../../utils/utils'
import clsx from 'clsx'
import UpdateReferralConfigUnavailableUntil from './UpdateReferralConfigUnavailableUntil'

const AdminReferralsConfigUsers = () => {
  const {
    loading: configsLoading,
    error: configsError,
    data: configsData
  } = useQuery(GET_REFERRALS_CONFIGS_WITH_DATA, {})

  const [rearrangedConfig, setRearrangedConfig] = useState({})
  const { locale } = useLocalization()
  const [highlightedCarregiver, setHighlightedCarregiver] = useState(null)
  const [configsToUpdate, setConfigsToUpdate] = useState(null)

  useEffect(() => {
    let _rearrangedConfig = {}
    if (configsData) {
      configsData.referralsConfigs.forEach(({ id, config }) => {
        config.forEach((configItem) => {
          let item = {
            ...configItem,
            configId: id
          }
          if (_rearrangedConfig[configItem.caregiver.id]) {
            _rearrangedConfig[configItem.caregiver.id].push(item)
          } else {
            _rearrangedConfig[configItem.caregiver.id] = [item]
          }
        })
      })

      setRearrangedConfig(_rearrangedConfig)
    }
  }, [configsData])

  return (
    <div className='mt-5'>
      {configsError && <div> error... </div>}

      {configsToUpdate && (
        <UpdateReferralConfigUnavailableUntil
          configsToUpdate={configsToUpdate}
          onComplete={() => setConfigsToUpdate(null)}
          onExit={() => setConfigsToUpdate(null)}
        />
      )}

      {configsLoading ? (
        <Loader />
      ) : (
        Object.keys(rearrangedConfig).length > 0 && (
          <div>
            <table className='table mt-5 '>
              <thead>
                <tr>
                  <th>User</th>
                  <th>Unavailable until</th>
                  <th>Config</th>
                  <th>Referral type</th>
                  <th>Study protocol</th>
                  <th>Price</th>
                  <th>Currrency</th>
                </tr>
              </thead>
              <tbody>
                {[...Object.keys(rearrangedConfig)]
                  .sort((c1, c2) =>
                    rearrangedConfig[c1][0].caregiver.name.localeCompare(
                      rearrangedConfig[c2][0].caregiver.name
                    )
                  )
                  .map((caregiverId) =>
                    rearrangedConfig[caregiverId].map(
                      (caregiverConfig, configIndex) => (
                        <tr
                          key={`${caregiverId}_${configIndex}`}
                          className={clsx('c-hand', {
                            'is-selected': highlightedCarregiver === caregiverId
                          })}
                          onClick={() => {
                            setHighlightedCarregiver(
                              highlightedCarregiver === caregiverId
                                ? null
                                : caregiverId
                            )
                          }}
                        >
                          {configIndex === 0 && (
                            <td
                              rowSpan={rearrangedConfig[caregiverId].length}
                              className='is-vcentered'
                            >
                              {`${caregiverConfig.caregiver.name}${
                                !caregiverConfig.isQueue
                                  ? ` (${caregiverConfig.caregiver.email})`
                                  : ''
                              }`}
                            </td>
                          )}
                          {configIndex === 0 && (
                            <td
                              rowSpan={rearrangedConfig[caregiverId].length}
                              className='is-vcentered has-text-centered'
                            >
                              <div className='is-flex'>
                                <span className='mr-4 mt-1'>
                                  {caregiverConfig.unavailableUntil}
                                </span>
                                <button
                                  className='button'
                                  href='#'
                                  onClick={(e) => {
                                    setConfigsToUpdate(
                                      rearrangedConfig[caregiverId]
                                    )
                                    e.stopPropagation()
                                  }}
                                >
                                  Edit
                                </button>
                              </div>
                            </td>
                          )}
                          <td>{caregiverConfig.configId}</td>
                          <td>
                            {
                              locale.studies.reports[
                                REFERRAL_TYPES_CONVERTER[
                                  caregiverConfig.referralType
                                ]
                              ]
                            }
                          </td>
                          <td>{caregiverConfig.studyProtocol}</td>
                          <td>{caregiverConfig.price}</td>
                          <td>{caregiverConfig.currency}</td>
                        </tr>
                      )
                    )
                  )}
              </tbody>
            </table>
          </div>
        )
      )}
    </div>
  )
}

export default AdminReferralsConfigUsers
