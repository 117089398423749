import AWS from 'aws-sdk/global'
import dayjs from 'dayjs'

function pad(n, width, z) {
  z = z || '0'
  n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
}

export const IS_BROWSER = typeof window !== 'undefined'

export const encodeViewerState = (state) =>
  JSON.stringify(Object.fromEntries(new URLSearchParams(state)))

export const decodeViewerState = (state) => {
  let decodedState = ''
  if (state) {
    decodedState = '#'
    const stateObject = JSON.parse(state)
    Object.keys(stateObject).forEach((key) => {
      decodedState = decodedState + `${key}=${stateObject[key]}&`
    })
    decodedState = decodedState.slice(0, -1)
  }
  return decodedState
}

export const hashPassword = (email, password) => {
  let hash = AWS.util.crypto.sha256(
    (password || '') + (email || '').toLowerCase(),
    'buffer'
  )
  for (var i = 0; i < 4999; i++) {
    hash = AWS.util.crypto.sha256(hash, 'buffer')
  }
  return hash.toString('hex')
}

export const base64UrlEncode = (str) =>
  AWS.util.base64
    .encode(str)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+/, '')

export const base64UrlDecode = (str) =>
  AWS.util.base64.decode(str.replace(/-/g, '+').replace(/_/g, '/')).toString()

const encode = (text) => encodeURIComponent(text).replace(/%/g, '$')
const awsEncode = (text) =>
  encodeURIComponent(encodeURIComponent(text)).replace(/%/g, '$')
// https://stackoverflow.com/questions/60796991/is-there-a-way-to-generate-the-aws-console-urls-for-cloudwatch-log-group-filters
export const generateCloudWatchLink = (
  region,
  logGroup,
  logStream,
  params // filterPattern, start, end
) =>
  `https://${region}.console.aws.amazon.com/cloudwatch/home?region=${region}#logsV2:log-groups/log-group/${awsEncode(
    logGroup
  )}/log-events/${awsEncode(logStream)}${encode('?')}${Object.keys(params)
    .map((key) => encode(`${key}=`) + awsEncode(params[key]))
    .join(encode('&'))}`

export const generateXRayLink = (region, traceId) =>
  `https://${region}.console.aws.amazon.com/cloudwatch/home?region=${region}#xray:traces/${traceId}`

export const filterSensitiveData = (text) =>
  text
    ? text.replace(
        /^(.*)(Password|password|cryptedPassword|access_secret|accessSecret|Authorization|authorization|x-amz-security-token).*$/gm,
        '$1$2": "FILTERED_SENSITIVE_DATA",'
      )
    : text

export const interpolateConclusionTemplate = (template, params) =>
  template
    .replaceAll('%name%', params.settings['displayName'])
    .replaceAll('%id%', '%uId%')
    .replaceAll(/%.*?%/g, (entry) =>
      entry
        .replaceAll('%', '')
        .split('.')
        .reduce((obj, key) => obj[key] || '', params)
    )

export const durationToHuman = (duration) => {
  if (!Number.isInteger(duration)) duration = 0
  duration /= 1000
  const hours = Math.floor(duration / 3600)
  const totalSeconds = Math.floor(duration % 3600)
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60
  return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}`
}

export const generateDefaultName = (
  firstName = '',
  middleName,
  lastName = ''
) =>
  `${firstName.trim()}${middleName ? ` ${middleName.trim().charAt(0)}.` : ''}${
    lastName ? ` ${lastName.trim()}` : ''
  }`

export const generateDefaultDisplayName = (name) => (name ? `Dr. ${name}` : '')

export const getParams = (search) => {
  if (!search) return {}
  search = search.slice(1)
  return search.split('&').reduce((obj, keyval) => {
    const tuple = keyval.split('=')
    obj[tuple[0]] = tuple[1]
    return obj
  }, {})
}

export const getCookie = (cookiesString, name) => {
  const value = `; ${cookiesString}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

export const COOKIE_LOCALE_TO_SCHEMA_LOCALE = {
  en: 'en_US',
  ru: 'ru_RU',
  uk: 'uk_UA',
  zh: 'zh_TW'
}

export const SCHEMA_LOCALE_TO_COOKIE_LOCALE = Object.keys(
  COOKIE_LOCALE_TO_SCHEMA_LOCALE
).reduce(
  (obj, key) => ((obj[COOKIE_LOCALE_TO_SCHEMA_LOCALE[key]] = key), obj),
  {}
)

export const UPLOAD_STATUS = {
  uploaded: 2,
  calculation: 4,
  report: 8,
  audio: 32,
  ecg_signal: 64
}

export const QRS_INTERVALS_CONVERTER = {
  RRintervals: 'rr',
  HeartRate: 'hr'
}

export const GRID_TYPE_CONVERTER = {
  GridType_5X5: 5,
  GridType_10X10: 10
}

export const DATE_FORMAT_CONVERTER = {
  DDMMYYYY: 'DD/MM/YYYY',
  MMDDYYYY: 'MM/DD/YYYY'
}

export const WEIGHT_CONVERTER = {
  [undefined]: 'kg',
  [null]: 'kg',
  Metric: 'kg',
  Imperial: 'lbs'
}

export const HEIGHT_CONVERTER = {
  [undefined]: 'cm',
  [null]: 'cm',
  Metric: 'cm',
  Imperial: 'feet'
}

export const PAPER_SPEED_CONVERTER = {
  PaperSpeed_5: 5,
  PaperSpeed_10: 10,
  PaperSpeed_25: 25,
  PaperSpeed_50: 50,
  PaperSpeed_100: 100
}

export const AMPLITUTE_CONVERTER = {
  Amplitude_2: 2,
  Amplitude_5: 5,
  Amplitude_10: 10,
  Amplitude_20: 20,
  Amplitude_40: 40
}

export const SHARING_STATUS_CONVERTER = {
  WaitingForSignup: 'User not registered',
  Pending: 'pending',
  Active: 'active',
  Deactive: 'inactive'
}

export const REFERRAL_TYPES_CONVERTER = {
  ReferralDetailedAnalysis: 'rest_analysis',
  ReferralHolterAnalysis: 'holter_analysis',
  ReferralPreanesthetic: 'preanesthetic',
  ReferralDiagnostic: 'diagnostic',
  ReferralRecheckDiagnostic: 'recheck_diagnostic',
  ReferralFivasovic1: 'fivasovic1',
  ReferralFivasovic2: 'fivasovic2',
  ReferralActiveCvcaPatient: 'active_cvca_patient',
  ReferralInHouseConsult: 'in_house_consult',
  ReferralEcgScreen: 'ecg_screen',
  ReferralPreop: 'preop',
  ReferralSameDay: 'same_day',
  ReferralStat: 'stat',
  ReferralEcgEcho: 'ecg_echo',
  ReferralEcgRads: 'ecg_rads',
  ReferralAutomaticAnalysis: 'automatic_analysis'
}

export const REFERRAL_TYPES_CONVERTER_FROM_LEGACY = Object.fromEntries(
  Object.entries(REFERRAL_TYPES_CONVERTER).map((a) => a.reverse())
)

export const GENDER_NEW_TO_LEGACY = {
  Male: 1,
  Female: 2,
  Unknown: 0,
  [undefined]: 0,
  [null]: 0
}

export const RECORD_TYPE_NEW_TO_LEGACY_STRING = {
  RecordEcgRestData: 'rest',
  RecordEcgHolterData: 'holter',
  RecordStethoscopeData: 'stethoscope',
  RecordSpirometerData: 'spirometer',
  RecordQuestionnaireData: 'questionnaire',
  RecordAttachmentData: 'attachments'
}

export const ANIMAL_TYPE_NEW_TO_LEGACY = {
  Other: 0,
  Horse: 1,
  BigDog: 2,
  MediumDog: 3,
  SmallDog: 4,
  Cat: 5,
  Rabbit: 6,
  Ferret: 7,
  Cavy: 8,
  Rat: 9,
  Dog: 10,
  Bird: 11
}

export const ANIMAL_TYPE_NEW_TO_LEGACY_STRING = {
  Other: 'other',
  Horse: 'horse',
  BigDog: 'big_dog',
  MediumDog: 'medium_dog',
  SmallDog: 'small_dog',
  Cat: 'cat',
  Rabbit: 'rabbit',
  Ferret: 'ferret',
  Cavy: 'cavy',
  Rat: 'rat',
  Dog: 'dog',
  Bird: 'bird'
}

export const ANIMAL_TYPE_LEGACY_TO_NEW = {
  [undefined]: 'Other',
  [null]: 'Other',
  '': 'Other',
  0: 'Other',
  1: 'Horse',
  2: 'BigDog',
  3: 'MediumDog',
  4: 'SmallDog',
  5: 'Cat',
  6: 'Rabbit',
  7: 'Ferret',
  8: 'Cavy',
  9: 'Rat',
  10: 'Dog',
  11: 'Bird'
}

// https://www.apollographql.com/docs/react/caching/cache-interaction/#using-cachemodify
export const objectToFieldsConverter = (object) => {
  return Object.keys(object).reduce((obj, key) => {
    obj[key] = (cached) => {
      if (Object.prototype.toString.call(object[key]) === '[object Object]')
        return { ...cached, ...object[key] }
      return object[key]
    }
    return obj
  }, {})
}

// https://stackoverflow.com/questions/286141/remove-blank-attributes-from-an-object-in-javascript
export const removeEmptyValues = (obj) => {
  return Object.entries(obj)
    .filter(([, v]) => v != null)
    .reduce(
      (acc, [k, v]) => ({
        ...acc,
        [k]: v === Object(v) ? removeEmptyValues(v) : v
      }),
      {}
    )
}

// https://stackoverflow.com/questions/42736031/remove-empty-objects-from-an-object
export const removeEmptyObjects = (obj) => {
  for (var k in obj) {
    if (!obj[k] || typeof obj[k] !== 'object') {
      continue // If null or not an object, skip to the next iteration
    }

    // The property is an object
    removeEmptyObjects(obj[k]) // <-- Make a recursive call on the nested object
    if (Object.keys(obj[k]).length === 0) {
      delete obj[k] // The object had no properties, so delete that property
    }
  }
}

export const removeEmptyValuesAndObjects = (obj) => {
  const a = removeEmptyValues(obj)
  removeEmptyObjects(a)
  return a
}

export const calculateAge = (_birthDate, _studyDate) => {
  let age = 0
  let units = 'Years'

  if (_birthDate) {
    const studyDate = dayjs(_studyDate)
    const birthDate = dayjs(_birthDate, 'YYYY-MM-DD')
    const daysDiff = studyDate.diff(birthDate, 'day')
    const monthsDiff = studyDate.diff(birthDate, 'month')
    const yearsDiff = studyDate.diff(birthDate, 'year')
    if (daysDiff < 31) {
      age = daysDiff
      units = 'Days'
    } else if (monthsDiff < 12) {
      age = monthsDiff
      units = 'Months'
    } else {
      age = yearsDiff
      units = 'Years'
    }
  }

  return {
    age,
    units
  }
}

export const getAgeUnitsLocalizationKey = (age, units) => {
  let keys = {
    days: function (val) {
      if (val == 1) return 'day_old'
      else if (val < 5) return 'few_days_old'
      else return 'days_old'
    },
    months: function (val) {
      if (val == 1) return 'month_old'
      else if (val < 5) return 'few_months_old'
      else return 'months_old'
    },
    years: function (val) {
      if (val == 1) return 'year_old'
      else if (val < 5) return 'few_years_old'
      else return 'years_old'
    }
  }
  return keys[units](age)
}

export const RECORDS_PRIORITIES = {
  RecordEcgRestData: 0,
  RecordEcgHolterData: 1,
  RecordStethoscopeData: 2,
  RecordSpirometerData: 3,
  RecordAttachmentData: 4,
  RecordQuestionnaireData: 5
}

export const isSerializableObject = (obj) =>
  typeof obj === 'object' && !Array.isArray(obj) && !!obj

export const isAuthorizedAction = (actions, reference) =>
  !actions || !reference
    ? false
    : actions.deny.find((action) =>
        new RegExp(
          '^' +
            action
              .replace(/[-[\]/{}()+.\\^$|]/g, '\\$&')
              .replace(/\*/g, '.*')
              .replace(/\?/g, '.') +
            '$'
        ).test(reference)
      )
    ? false
    : !!actions.allow.find((action) =>
        new RegExp(
          '^' +
            action
              .replace(/[-[\]/{}()+.\\^$|]/g, '\\$&')
              .replace(/\*/g, '.*')
              .replace(/\?/g, '.') +
            '$'
        ).test(reference)
      )

export const ANONYMOUS_USER_ID = 'Anonymous'
export const ANONYMOUS_CLINIC_ID = 'Anonymous'
export const ANONYMOUS_SERVICE_PLAN_ID = 'Anonymous'
export const ANONYMOUS_PATIENT_NAME = 'Anonymous'
export const BEECARDIA_ID = 'Beecardia'

// https://stackoverflow.com/questions/12006095/javascript-how-to-check-if-character-is-rtl/14824756#14824756
export const isRTL = (s) => {
  var ltrChars =
      'A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF' +
      '\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF',
    rtlChars = '\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC',
    rtlDirCheck = new RegExp('^[^' + ltrChars + ']*[' + rtlChars + ']')

  return rtlDirCheck.test(s)
}

export const localizePatientName = (name, locale) => {
  return name.toLowerCase() === 'anonymous' ? locale.anonymous : name
}

export const adjustTimeToTimezone = (GMTTime, timezoneOffset) => {
  const localTimezoneOffset = new Date(GMTTime).getTimezoneOffset()
  const date = dayjs(new Date(GMTTime))
  return date.add(
    (localTimezoneOffset + timezoneOffset) * 60 * 1000,
    'millisecond'
  )
}

export const MEA_LABEL = {
  '': 'novalue',
  null: 'novalue',
  undefined: 'novalue',
  Normal: 'normal',
  Mild: 'mild_right_axis_deviation',
  Moderate: 'moderate_right_axis_deviation',
  Severe: 'severe_right_axis_deviation',
  Right: 'right_axis_deviation',
  Left: 'left_axis_deviation'
}

const isDST = (date) => {
  let jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset()
  let jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset()
  return Math.max(jan, jul) !== date.getTimezoneOffset()
}

export const convertPSTToUTC = (date) => {
  const localTimezoneOffset = date.toDate().getTimezoneOffset()
  return date.add(
    ((isDST(date.toDate()) ? 7 : 8) * 60 - localTimezoneOffset) * 60 * 1000,
    'millisecond'
  )
}

export const groupBy = function (list, keyGetter) {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

export const wrapRequestWithTimeoutRetryHandler = (request) => {
  request.on('retry', function (response) {
    if (response.error && response.error.code === 'TimeoutError') {
      response.error.retryable = true
      console.error(
        JSON.stringify(
          {
            step: 'Notification - request retry',
            retryCount: response.retryCount,
            error: response.error
          },
          null,
          2
        )
      )
    }
  })
  return request
}

export const EU_CODES = [
  'AD',
  'AL',
  'AT',
  'AX',
  'BA',
  'BE',
  'BG',
  'BY',
  'CH',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FO',
  'FR',
  'GB',
  'GG',
  'GI',
  'GR',
  'HR',
  'HU',
  'IE',
  'IM',
  'IS',
  'IT',
  'JE',
  'LI',
  'LT',
  'LU',
  'LV',
  'MC',
  'MD',
  'ME',
  'MK',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'RS',
  'RU',
  'SE',
  'SI',
  'SJ',
  'SK',
  'SM',
  'UA',
  'VA'
]
