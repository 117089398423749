if (typeof window !== 'undefined') {

/*
    View - the viewer main view
*/
bcViewer.View = function(globals) {
    this.globals = globals;
    $.extend(this, globals);

    // Save the size of bc_viewer, there are 2 options:
    // 1) dimensions are set in the config
    // 2) if there are no dimensions in the config, use 100%
    $$('#bc_viewer').width(this._config.BC_VIEWER_WIDTH || '100%');
    $$('#bc_viewer').height(this._config.BC_VIEWER_HEIGHT || '100%');

    this._width  = $$('#bc_viewer').width();
    this._height = $$('#bc_viewer').height();

    // flag - true if resize started
    this._isResizeStarted = false;
    if (!this._bcViewer.isRemoteMonitor) {
        if ((typeof this._config.BC_VIEWER_WIDTH === 'undefined') ||
           (typeof this._config.BC_VIEWER_HEIGHT === 'undefined')) {
            // handle resizing in order to fit 100%
            // TODO: When resizing, the HR and events viewers sometimes get the wrong width.
            //       It happens becase on resize we send requests for data
            //       and sometimes we send multiply requests which can return data for wrong dimensions.
            //       We need to make sure we don't send 2 requests with the same dimensions
            //       and that we don't accept answers with different dimensions than the current dimensions.
            $(window).on('resize', $.proxy(function() {
                if (this._isResizeStarted === false) {
                    var isWidthChanged = (this._width !== $$('#bc_viewer').width());
                    var isHeightChanged = (this._height !== $$('#bc_viewer').height());
                    this._bcViewer._allViewerDo('onWindowStartResizing', isWidthChanged, isHeightChanged);
                    this._isResizeStarted = true;
                }
                this._utils.throttle(this, 'resize', this._onWindowResize);
            }, this));
        }
    }

    this._bcViewer.isRealtimeMode && $$('#bc_viewer').addClass('bcv_realtime');
    this._bcViewer.isRemoteMonitor && $$('#bc_viewer').addClass('bcv_remote_monitor');

     // Below that width we will load low resolution css
    this._lowResolutionWidthLimit = this._config.appConfig.LOW_RESOLUTION_WIDTH_LIMIT;

    var width = document.documentElement.clientWidth;

    if (this._bcViewer.mode === this._bcViewer.modes.IN_APP) {
        $$('#bc_viewer').addClass('in_app_viewer');

        if (width < this._lowResolutionWidthLimit) {
            $$('#bc_viewer').addClass('low_res');
        }
    }

    this._setECGViewerPosition();

    // Temporary solution until we'll move session details into the viewer
    if (this._config.HIDE_HEADER) {
        $$('.bcv_header').css('display', 'none');
    }

    this._config.controlPanel.HIDDEN && $$('.bcv_ecg_control_panel').hide();

    if (this._bcViewer.reportMode) {
        $$('.bcv_top').hide();
    }


    // Init all tooltips
    $('#bc_viewer').tooltip();
    this._bcViewer.reportMode && $$('#bc_viewer').addClass('report_mode');

    this._bcViewer.isFullscreenAvailable() && $$('#bc_viewer').addClass('fullscreen_available');

    this._NARROW_MODE_WIDTH_LIMIT = 720;
    this._handleViewerWidth();
};

/*
    handles save record complete
*/
bcViewer.View.prototype.onSaveRecordToServerProgress = function(progressValue) {
    // TODO: we need localization support here
  // $('#main_loading').html(progressValue);
  // if (progressValue == '100') {
  //   $('#main_loading').hide();
  // }
};

/*
    handles resizing
*/
bcViewer.View.prototype._onWindowResize = function() {
   if (this._bcViewer.mode === this._bcViewer.modes.IN_APP) return;

   // if rezising it means we are trying to fit 100% so we need to update the width\height
   this._width = $$('#bc_viewer').width();
   this._height = $$('#bc_viewer').height();

   this._handleViewerWidth();
   this._bcViewer.onStateChangeRequest({
        'width': this._width,
        'height': this._height
    });

    this._isResizeStarted = false;
};

/*
    Resize the viewer
*/
bcViewer.View.prototype.resize = function(obj) {
    if (typeof obj.width !== 'undefined') {
        $$('#bc_viewer').width(obj.width);
        this._width = obj.width;
    }
    if (typeof obj.height !== 'undefined') {
        $$('#bc_viewer').height(obj.height);
        this._height = obj.height;
    }
    this._handleViewerWidth();
    this._bcViewer.onStateChangeRequest({
        'height': this._height,
        'width': this._width
    });
};

/*
    return the bc_viewer width\height
*/
bcViewer.View.prototype.getDimensions = function() {
    return {'width':this._width, 'height':this._height};
};

/*
    add the correct top\bottom values of the ECG viewer to the config
*/
bcViewer.View.prototype._setECGViewerPosition = function() {
    var controlPanelHeight = this._config.controlPanel.HIDDEN ? 0 : $$('.bcv_ecg_control_panel').outerHeight();
    var currentViewInfoHeight = this._bcViewer.reportMode ? 0 : $$('.bcv_current_view_info').outerHeight();

    this._config.ecgViewer.BOTTOM = $$('.bcv_context_viewer').height() + controlPanelHeight;

    var HRViewerHeight = (this._config.HRViewer.HIDDEN) ? 0 : this._config.HRViewer.HEIGHT + $$('.bcv_hr_header').cssNumber('border-bottom');
    var eventsViewerHeight = (this._config.eventsViewer.HIDDEN) ? 0 : this._config.eventsViewer.HEIGHT;
    var timelineViewerHeight = (this._config.timelineViewer.HIDDEN) ? 0 : this._config.timelineViewer.HEIGHT + $$('.bcv_timeline_header').cssNumber('border-bottom');
    var headerHeight = (this._config.HIDE_HEADER) ? 0 : $$('.bcv_header').outerHeight() + $$('.bcv_header').cssNumber('margin-bottom');
    this._config.ecgViewer.TOP = headerHeight +
                                 currentViewInfoHeight +
                                 HRViewerHeight +
                                 eventsViewerHeight +
                                 timelineViewerHeight +
                                 $$('.bcv_events_container').cssNumber('border-top') +
                                 $$('.bcv_events_container').cssNumber('border-bottom');

    if (this._bcViewer.isRealtimeMode) {
        this._config.ecgViewer.TOP = 0;
    }

    if (this._config.contextViewer.HIDDEN) {
        var isControlPanelHidden = ($$('.bcv_ecg_control_panel').css('display') === 'none') || this._config.controlPanel.HIDDEN;
        var controlPanelHeight = !isControlPanelHidden ? $$('.bcv_ecg_control_panel').outerHeight() : 0;
        var controlPanelBorder = !isControlPanelHidden ? $$('.bcv_ecg_control_panel').cssNumber('border-top') : 0;
        this._config.ecgViewer.BOTTOM = controlPanelHeight +
                                        controlPanelBorder +
                                        $$('.bcv_canvas_wrapper').cssNumber('border-top');
    }
};

/*
    Handle metadata
*/
bcViewer.View.prototype.onGotMetadata = function(metadata) {
    // Set class according to record type
    var recordType = this._bcViewer.getRecordType();
    var types = this._bcViewer._recordTypes;
    for (type in types) {
        if (types[type] === recordType) {
            var recordTypeClass = 'record_type_' + type.toLowerCase();
            $$('#bc_viewer').addClass(recordTypeClass);
            break;
        }
    }

    var studyType = this._bcViewer.getStudyType();
    var studyTypes = this._bcViewer._studyTypes;
    for (type in studyTypes) {
        if (studyTypes[type] === studyType) {
            var studyTypeClass = 'study_type_' + type.toLowerCase();
            $$('#bc_viewer').addClass(studyTypeClass);
            break;
        }
    }

    // set class accotding to electrodes position
    var positions = this._bcViewer.electrodesPositions;
    for (pos in positions) {
        if (positions[pos].id === metadata.electrodesPosition) {
            var positionClass = 'electrodes_' + pos.toLowerCase();
            $$('#bc_viewer').addClass(positionClass);
            break;
        }
    }
};

bcViewer.View.prototype._handleViewerWidth = function() {
    $$('#bc_viewer').toggleClass('narrow_mode', this._width<this._NARROW_MODE_WIDTH_LIMIT);
};

}
